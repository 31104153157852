import React, { Component } from 'react';
// @ts-ignore
import styles from './index.module.scss';
import moment from 'moment';
import { columns } from './columns';
import { Spin, Empty } from 'antd';


class CustomerStockTable extends Component {

    del = (stockId) => {
        let { delStock } = this.props;
        delStock && delStock(stockId);
    }

    modify = (stockId) => {
        let { onModify } = this.props;
        onModify && onModify(stockId);
    }

    warranty = (stockId) => {
        let { selectWarranty } = this.props;
        selectWarranty && selectWarranty(stockId);
    }

    render() {
        let { dataSource } = this.props;
        return (
            <div className={styles.table}>
                <Spin spinning={this.props.dataLoading}>
                    {(dataSource && dataSource.length === 0) && (
                        <div className={styles.empty}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                    {dataSource.map((stock, index) => {
                        return (
                            <div key={index} className={styles.tableItem}>
                                <div className={styles.consigneeBox}>
                                    <div className={styles.primaryLabel}>车牌号:</div>
                                    <div className={styles.licensePlate}>{stock.licensePlate}</div>
                                    <div className={styles.outTime}>{moment(stock.outTime).format("YYYY-MM-DD HH:mm")}</div>
                                </div>
                                <div className={styles.itemBox}>
                                    <div className={styles.thead}>
                                        {columns.map((column, index) => {
                                            return (
                                                <div key={index} style={{
                                                    width: column.width,
                                                    // @ts-ignore
                                                    textAlign: column.align
                                                }}>
                                                    {column.title}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.tbody}>
                                        {stock.stockProducts && (stock.stockProducts.map((stockProduct, index) => {
                                            return (
                                                <div key={index} className={styles.trow}>
                                                    <div className={styles.rowField} style={{ width: '18%' }}>
                                                        {stockProduct.batchNo}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: '15%' }}>
                                                        {stockProduct.product.prodName}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: '19%' }}>
                                                        {stockProduct.grade}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: '18%', textAlign: 'center' }}>
                                                        {stockProduct.product.prodName !== '钢坯' ? `⌀${stockProduct.product.spec}`
                                                            : stockProduct.product.spec}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: '14%', textAlign: 'center' }}>
                                                        {`${stockProduct.product.length}m`}
                                                    </div>
                                                    <div style={{ width: '15%', textAlign: 'right' }}>
                                                        {stockProduct.product.prodName === '钢坯' ? `${stockProduct.number}支`
                                                            : `${stockProduct.number}捆`}
                                                    </div>
                                                </div>
                                            );
                                        }))}
                                    </div>
                                    <div className={styles.curatorBox}>
                                        <div className={styles.curatorLabel}>出库人:</div>
                                        <div className={styles.curatorValue}>{stock.curator}</div>
                                    </div>
                                    <div className={styles.opera}>
                                        <div
                                            className={styles.warrantySheetBtn}
                                            onClick={() => this.warranty(stock.stockId)}
                                        >
                                            下载质保单
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Spin>
            </div>
        );
    }
}

export default CustomerStockTable;
