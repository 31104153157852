import React, { Component } from 'react';
import styles from './index.module.scss';
import { getUserInfo, logout, modifyPassword } from '../../api/user';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Avatar, Icon, message, Modal, Input } from 'antd';
const { confirm } = Modal;
@observer
class StatusBar extends Component {


    @observable
    user = {};

    @observable
    dropShow = false;

    @observable
    modifyPassword = false;

    @observable
    originPassword;

    @observable
    newPassword;

    @observable
    confirmPassword;

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser = async () => {
        let resp = await getUserInfo();
        let { data } = resp;
        if (data.code === 0) {
            this.user = data.data;
        }
    }

    showConfirm = () => {
        confirm({
            title: '您确定退出登录吗?',
            content: '点击确定按钮退出登录，点击取消按钮返回',
            onOk: () => this.logout(),
            onCancel() { },
        });
    }

    logout = async () => {
        let resp = await logout();
        let { data } = resp;
        if (data.code === 0) {
            document.cookie = "token=; -1";
            message.success("退出成功");
            window.location.href = '/';
        }
    }

    onDropMouseon = () => {
        this.dropShow = true;
    }

    onDropMouseout = () => {
        this.dropShow = false;
    }

    onOriginPasswordChange = (e) => {
        this.originPassword = e.target.value;
    }

    onNewPasswordChange = (e) => {
        this.newPassword = e.target.value;
    }

    onConfirmPasswordChange = (e) => {
        this.confirmPassword = e.target.value;
    }

    submitModifyPassword = async () => {
        let { originPassword, newPassword, confirmPassword } = this;
        if (!originPassword || originPassword === '') {
            message.error('请输入原密码');
            return;
        }
        if (!newPassword || newPassword === '') {
            message.error('请输入新密码');
            return;
        }
        if (!confirmPassword || confirmPassword === '') {
            message.error('请确认新密码');
            return;
        }
        if (newPassword.length < 6) {
            message.error('新密码最少6位');
            return;
        }
        if (confirmPassword !== newPassword) {
            message.error('两次输入的新密码不一致');
            return;
        }
        let resp = await modifyPassword(originPassword, newPassword);
        let { data } = resp;
        if (data.code === 0) {
            message.success("修改成功");
            this.modifyPassword = false;
            this.originPassword = undefined;
            this.newPassword = undefined;
            this.confirmPassword = undefined;
        } else {
            message.error(data.msg);
        }
    }

    render() {
        return (
            <div className={styles.statusBar}>
                <div className={styles.sysInfo}>
                    <img
                        src='/logo.png'
                        alt='logo'
                        style={{
                            width: 'auto',
                            height: '18px',
                            marginRight: '12px'
                        }}
                    />
                    兴华钢铁质保单自助管理系统
                </div>
                <div className={styles.userInfo}>
                    <div className={styles.logout} onClick={this.showConfirm}>
                        <Icon type="logout" style={{ paddingTop: 2, marginRight: 8 }} />
                        <div>退出登录</div>
                    </div>
                    <div className={styles.avatar} onMouseEnter={this.onDropMouseon} onMouseLeave={this.onDropMouseout}>
                        <Avatar
                            style={{ backgroundColor: '#C22F27', verticalAlign: 'middle' }}
                            size='default'
                        >
                            {this.user.userName && this.user.userName.split('')[0]}
                        </Avatar>
                    </div>
                    {this.dropShow && (<div className={styles.topDrop} onMouseEnter={this.onDropMouseon} onMouseLeave={this.onDropMouseout}>
                        <div className={styles.dropItem} onClick={() => this.modifyPassword = true}>修改密码</div>
                    </div>)}
                </div>
                <Modal
                    title='修改密码'
                    visible={this.modifyPassword}
                    onCancel={() => {
                        this.modifyPassword = false;
                        this.originPassword = undefined;
                        this.newPassword = undefined;
                        this.confirmPassword = undefined;
                    }}
                    width={600}
                    onOk={() => this.submitModifyPassword()}
                >
                    <div className={styles.modifyPassword}>
                        <div className={styles.row}>
                            <div className={styles.th}>原密码:</div>
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                className={styles.data}
                                placeholder='请输入您的原密码'
                                style={{ width: 250 }}
                                value={this.originPassword}
                                onChange={this.onOriginPasswordChange}
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.th}>新密码:</div>
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                className={styles.data}
                                placeholder='请输入您的新密码'
                                style={{ width: 250 }}
                                value={this.newPassword}
                                onChange={this.onNewPasswordChange}
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.th}>确认密码:</div>
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                className={styles.data}
                                placeholder='请确认您的新密码'
                                style={{ width: 250 }}
                                value={this.confirmPassword}
                                onChange={this.onConfirmPasswordChange}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default StatusBar;