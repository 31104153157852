import { request } from '../utils/http';

export const getGradeList = () => {
    return request(
        '/grade/getList',
        'POST',
    );
};

export const addGrade = (params) => {
    return request(
        '/grade/insert',
        'POST',
        params
    );
};

export const delGrade = (params) => {
    return request(
        '/grade/delete',
        'POST',
        params
    );
};

export const getById = (params) => {
    return request(
        '/grade/getById',
        'POST',
        params
    );
};

export const update = (params) => {
    return request(
        '/grade/update',
        'POST',
        params
    );
};

export const getGradeNameList = () => {
    return request(
        '/grade/getGradeList',
        'POST',
    );
};