import React, { Component } from 'react';
import { Form, Input, Button, Select } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import { allRole } from '../../../api/role';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const { Option } = Select;

@observer
class ModifyUser extends Component {

    @observable loading = false;
    @observable roleList = [];

    componentDidMount() {
        this.fetchRoleList();
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { user } = this.props;
                let { modifyUser } = this.props;
                modifyUser && modifyUser({userId: user.userId, ...values});
                this.props.form.resetFields();
            }
        });
    };

    fetchRoleList = async () => {
        try {
            this.loading = true;
            const resp = await allRole();
            const { data } = resp;
            if (data.code === 0) {
                this.roleList = data.data;
            }
        } finally {
            this.loading = false;
        }
    }

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let { user } = this.props;
        if (!user) {
            user = {};
        }
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div className={styles.row}>
                        <Form.Item label='用户名'>
                            {getFieldDecorator('userName',
                                {
                                    rules: [{ required: true, message: '请输入用户名' },],
                                    initialValue: user.userName
                                })
                                (<Input maxLength={10} style={{ width: 250 }} placeholder='请输入用户名' />)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='手机号'>
                            {getFieldDecorator('mobile',
                                {
                                    rules: [
                                        { required: true, message: '请输入手机号码' },
                                        { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
                                    ],
                                    initialValue: user.mobile
                                })
                                (<Input maxLength={11} style={{ width: 250 }} placeholder='请输入手机号码' />)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='角色'>
                            {getFieldDecorator('roleId',
                                {
                                    rules: [{ required: true, message: '请选择角色' }],
                                    initialValue: user.roleId
                                })
                                (<Select style={{ width: 250 }} loading={this.loading} placeholder='请选择角色'>
                                    {this.roleList && this.roleList.map((role, index) => {
                                        return (
                                            <Option key={index} value={role.roleId}>{role.roleName}</Option>
                                        );
                                    }
                                    )}
                                </Select>)}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError()) || this.props.addSubmitting}
                                loading={this.props.addSubmitting}
                                style={{ minWidth: 100 }}
                            >
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ModifyUser);