import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
// @ts-ignore
import styles from './index.module.scss';

class ModifyRole extends Component {

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { modifyRole } = this.props;
                modifyRole && modifyRole(values);
                this.props.form.resetFields();
            }
        });
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let { role } = this.props;
        if (!role) {
            role = {};
        }
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div className={styles.row}>
                        <Form.Item label='角色名称'>
                            {getFieldDecorator('roleName',
                                {
                                    rules: [{ required: true, message: '请输入角色名称' },],
                                    initialValue: role.roleName
                                })
                                (<Input maxLength={10} style={{ width: 250 }} placeholder='请输入角色名称' />)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='角色描述'>
                            {getFieldDecorator('desc',
                                {
                                    rules: [{ required: true, message: '请输入角色描述' },],
                                    initialValue: role.desc
                                })
                                (<Input maxLength={50} style={{ width: 250 }} placeholder='请输入角色描述' />)}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError()) || this.props.addSubmitting}
                                loading={this.props.addSubmitting}
                                style={{ minWidth: 100 }}
                            >
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ModifyRole);;