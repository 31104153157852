import React, { Component } from 'react';
//@ts-ignore
import styles from './index.module.scss';
import { menus } from '../../api/user';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Menu, Icon, Affix } from 'antd';
import { withRouter } from 'react-router-dom';
import userState from '../../store/index';

// @ts-ignore
@withRouter
@observer
class TopMenu extends Component {

    @observable
    menus = [];

    @observable
    current;

    componentDidMount() {
        this.current = this.props.current;
        this.queryMenus();
    }

    queryMenus = async () => {
        let response = await menus();
        let { data } = response;
        let { code } = data;
        if (code === 0 && data.data) {
            this.menus = data.data;
            const menuListStore = userState;
            menuListStore.setMenuList(data.data);
            if (this.menus.length > 0 && !this.current) {
                this.current = this.menus[0].resource + '';
                this.menuClick(this.menus[0].resource);
            }
        }
    }

    handleClick = e => {
        this.current = e.key;
    };

    menuClick = (res) => {
        this.props.history.push('/' + res)
    }

    render() {
        return (
            <Affix offsetTop={10}>
                <div className={styles.menuBox}>
                    <Menu onClick={this.handleClick} selectedKeys={[this.current]} mode='vertical-left'>
                        {
                            this.menus.map((item) => {
                                return (
                                    <Menu.Item
                                        key={item.resource}
                                        onClick={() => this.menuClick(item.resource)}
                                    >
                                        <Icon type={item.icon || "folder"} />
                                        {item.menuName}
                                    </Menu.Item>
                                );
                            })
                        }
                    </Menu>
                </div>
            </Affix>
        );
    }
}

export default TopMenu;