import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { warrantySheet } from '../../api/stock';
import { message } from 'antd';
import BoundWarranty from './warranty/bound';
import DeformedWarranty from './warranty/deformed';
import BilletWarranty from './warranty/billet';

@observer
class UploadWarrantyImage extends Component {

    // 质保单列表
    @observable warrantyList = [];

    componentDidMount() {
        const stockId = this.props.match.params.stockId;
        this.fetchWarrantySheet(stockId);
    }

    /**
    *  获取质保单列表
    */
    fetchWarrantySheet = async (stockId) => {
        try {
            this.warrantyLoading = true;
            let response = await warrantySheet(stockId);
            let { data } = response;
            if (data.code === 0) {
                this.warrantyList = data.data;
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.warrantyLoading = false;
        }
    }

    render() {
        let { warrantyList } = this;
        return (
            <div>
                {warrantyList && warrantyList.map((warranty, index) => {
                    return (
                        <div key={index} style={{ position: 'relative' }}>
                            {warranty.productName === '热轧圆钢' && (<BoundWarranty warranty={warranty} />)}
                            {warranty.productName === '热轧带肋钢筋' && (<DeformedWarranty warranty={warranty} />)}
                            {warranty.productName === '连铸钢坯' && (<BilletWarranty warranty={warranty} />)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default UploadWarrantyImage;