

export function getAnalysisRule(gradeName) {
    const grade = analysisRules[gradeName];
    if (grade) {
        return grade;
    } else {
        return (
            {
                c: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '碳含量'
                },
                si: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '硅含量'
                },
                mn: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '锰含量'
                },
                p: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '磷含量'
                },
                s: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '硫含量'
                },
                v: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '钒含量'
                },
                ceq: {
                    min: 0,
                    max: 100,
                    required: false,
                    disabled: false,
                    placeholder: '碳当量'
                }
            }
        );
    }
}

export const analysisRules = {
    'HPB300': {
        c: {
            min: 0,
            max: 0.25,
            required: true,
            disabled: false,
            placeholder: '碳含量(≤0.25)'
        },
        si: {
            min: 0,
            max: 0.55,
            required: true,
            disabled: false,
            placeholder: '硅含量(≤0.55)'
        },
        mn: {
            min: 0,
            max: 1.50,
            required: true,
            disabled: false,
            placeholder: '锰含量(≤1.50)'
        },
        p: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '磷含量(≤0.045)'
        },
        s: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '硫含量(≤0.045)'
        },
        v: {
            min: 0,
            max: 0,
            required: false,
            disabled: true,
            placeholder: ''
        },
        ceq: {
            min: 0,
            max: 0,
            required: false,
            disabled: true,
            placeholder: ''
        }
    },
    '20Mn': {
        c: {
            min: 0.25,
            max: 0.30,
            required: true,
            disabled: false,
            placeholder: '碳含量(0.25-0.30)'
        },
        si: {
            min: 0.30,
            max: 0.50,
            required: true,
            disabled: false,
            placeholder: '硅含量(0.30-0.50)'
        },
        mn: {
            min: 0.90,
            max: 1.20,
            required: true,
            disabled: false,
            placeholder: '锰含量(0.90-1.20)'
        },
        p: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '磷含量(≤0.045)'
        },
        s: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '硫含量(≤0.045)'
        },
        v: {
            min: 0,
            max: 0,
            required: false,
            disabled: true,
            placeholder: ''
        },
        ceq: {
            min: 0,
            max: 0,
            required: false,
            disabled: true,
            placeholder: ''
        }
    },
    HRB400E: {
        c: {
            min: 0.22,
            max: 0.26,
            required: true,
            disabled: false,
            placeholder: '碳含量(0.22-0.26)'
        },
        si: {
            min: 0,
            max: 0.8,
            required: true,
            disabled: false,
            placeholder: '硅含量(≤0.80)'
        },
        mn: {
            min: 1.20,
            max: 1.60,
            required: true,
            disabled: false,
            placeholder: '锰含量(1.20-1.60)'
        },
        p: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '磷含量(≤0.045)'
        },
        s: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '硫含量(≤0.045)'
        },
        v: {
            min: 0.023,
            max: 0.12,
            required: true,
            disabled: false,
            placeholder: '钒含量(0.023-0.12)'
        },
        ceq: {
            min: 0.45,
            max: 0.54,
            required: true,
            disabled: false,
            placeholder: '碳当量(0.45-0.54)'
        }
    },
    Q235B: {
        c: {
            min: 0.12,
            max: 0.20,
            required: true,
            disabled: false,
            placeholder: '碳含量(0.12-0.20)'
        },
        si: {
            min: 0,
            max: 0.35,
            required: true,
            disabled: false,
            placeholder: '硅含量(≤0.35)'
        },
        mn: {
            min: 0.30,
            max: 0.70,
            required: true,
            disabled: false,
            placeholder: '锰含量(0.30-0.70)'
        },
        p: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '磷含量(≤0.045)'
        },
        s: {
            min: 0,
            max: 0.045,
            required: true,
            disabled: false,
            placeholder: '硫含量(≤0.045)'
        },
        v: {
            min: 0,
            max: 0,
            required: false,
            disabled: true,
            placeholder: ''
        },
        ceq: {
            min: 0,
            max: 1,
            required: false,
            disabled: true,
            placeholder: ''
        }
    }
}