import React, { Component, Fragment } from 'react';
// @ts-ignore
import styles from './index.module.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Input, message, Pagination, Modal } from 'antd';
import { stockList } from '../../api/stock';
import { sendCode, customerLogin } from '../../api/customer';
import CustomerStockTable from './list';
import { withRouter } from 'react-router-dom';
const { confirm } = Modal;
let maxTime = 60

// @ts-ignore
@withRouter
@observer
class Customer extends Component {

    @observable mobile;
    @observable code;
    @observable isLogin = false;
    @observable stocks = [];
    @observable sendCodeButton = '获取短信验证码';
    @observable sendCodeButtonBool = false;

    // 分页
    @observable pageNum = 1;
    @observable pageSize = 20;
    @observable total = 0;
    // 加载状态
    @observable loading = false;
    // 质保单数据
    @observable warrantySockId = '';

    onMobileChange = (e) => {
        this.mobile = e.target.value;
    }

    onCodeChange = (e) => {
        this.code = e.target.value;
    }

    componentDidMount() {
        if (localStorage.getItem("isLogin") === 'true' && localStorage.getItem("customerPhone") !== '') {
            this.isLogin = true;
            this.mobile = localStorage.getItem("customerPhone");
            this.queryStockList();
        }
    }

    componentWillUnmount() {
        this.timer = null;
    }

    /**
    * 查询出库记录列表
    */
    queryStockList = async () => {
        let { pageNum, pageSize } = this;
        try {
            this.loading = true;
            let response = await stockList({ pageNum, pageSize, phone: this.mobile });
            let { data } = response;
            if (data.code === 0) {
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
                this.stocks = data.data.list;
            }
            else {
                message.error("查询失败");
            }
        }
        finally {
            this.loading = false;
        }
    }

    onSelectWarranty = (stockId) => {
        this.warrantySockId = stockId;
        let { history } = this.props;
        history.push(`/customerWarranty/${stockId}`);
    }

    onSubmit = () => {
        if (!this.mobile || this.mobile.trim() === '') {
            message.warn('请输入手机号');
            return;
        }
        if (!(/^1[3456789]\d{9}$/.test(this.mobile))) {
            message.warn('请输入正确的手机号');
            return;
        }
        if (!this.code || this.code.trim() === '') {
            message.warn('请输入验证码');
            return;
        }
        this.postCustomerLogin();
    }

    sendCode() {
        if (!this.mobile || this.mobile.trim() === '') {
            message.warn('请输入手机号');
            return;
        }
        if (!this.sendCodeButtonBool) {
            maxTime = 60;
            this.timer = setInterval(() => {
                if (maxTime > 0) {
                    maxTime -= 1;
                    this.sendCodeButton = `(${maxTime})秒后重新获取`;
                    this.sendCodeButtonBool = true;
                }
                else {
                    this.timer = null;
                    this.sendCodeButton = `获取短信验证码`;
                    this.sendCodeButtonBool = false;
                }
            }, 1000);
            this.sendSmsCode();
        }
    }

    sendSmsCode = async () => {
        try {
            this.loading = true;
            let response = await sendCode(this.mobile);
            let { data } = response;
            if (data.code === 0) {
                message.success('短信发送成功');
            }
            else {
                message.error("短信发送失败");
            }
        }
        finally {
            this.loading = false;
        }
    }

    postCustomerLogin = async () => {
        try {
            this.loading = true;
            let response = await customerLogin(this.mobile, this.code);
            let { data } = response;
            if (data.data) {
                message.success('登录成功');
                this.isLogin = true;
                localStorage.setItem("isLogin", "true");
                localStorage.setItem("customerPhone", `${this.mobile}`);
                this.queryStockList();
            }
            else {
                message.error("短信验证码不正确");
            }
        }
        finally {
            this.loading = false;
        }
    }



    onShowSizeChange = (current, size) => {
        this.pageNum = current;
        this.pageSize = size;
        this.queryStockList();
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination;
        this.queryStockList();
    }

    logout = () => {
        confirm({
            title: '您确定退出登录吗?',
            onOk: () => {
                localStorage.removeItem("customerPhone");
                localStorage.removeItem("isLogin");
                this.isLogin = false;
                this.stocks = [];
                this.code = null;
            },
            onCancel() { },
        });
    }

    render() {
        const { pageNum, pageSize, total } = this;
        return (
            <Fragment>
                <div className={styles.top} style={{ position: 'relative' }}>
                    <img
                        src='/logo-white.png'
                        alt='logo'
                        style={{ width: 'auto', height: '1.5rem' }}
                    />
                    <div className={styles.name}>山西兴华钢铁有限公司</div>
                    {this.isLogin && (
                        <div className={styles.logout} onClick={this.logout}>退出</div>
                    )}
                </div>
                {!this.isLogin && (
                    <div style={{ position: 'relative' }}>
                        <div className={styles.login}>
                            <Input
                                type="tel"
                                className={styles.mobile}
                                placeholder='请输入手机号'
                                value={this.mobile}
                                maxLength={11}
                                onChange={this.onMobileChange}
                            />
                            <div className={styles.line}></div>
                            <div className={styles.codeBox}>
                                <Input
                                    type="tel"
                                    className={styles.code}
                                    placeholder='请输入验证码'
                                    value={this.code}
                                    onChange={this.onCodeChange}
                                    maxLength={4}
                                />
                                <div className={styles.hLine}></div>
                                <div className={styles.getCode}
                                    onClick={this.sendCode.bind(this)}
                                >
                                    {this.sendCodeButton}
                                </div>
                            </div>
                            <div className={styles.line}></div>
                        </div>
                        <div className={styles.submitButtonBox}>
                            <div
                                className={styles.submitButton}
                                onClick={this.onSubmit}
                            >
                                获取质保单
                            </div>
                        </div>
                        <div className={styles.support}>{`技术支持: 大数金科网络技术有限公司`}</div>
                    </div>
                )}
                {this.isLogin && (
                    <Fragment>
                        <CustomerStockTable
                            dataSource={this.stocks}
                            dataLoading={this.loading}
                            selectWarranty={this.onSelectWarranty}
                        />
                        <div className={styles.pagination}>
                            {(this.stocks && this.stocks.length > 0) && (
                                <Pagination
                                    simple={true}
                                    current={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                    onChange={this.onTableChange}
                                />
                            )}
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Customer;