import { request } from '../utils/http';

export const login = (mobile, password) => {
    return request(
        '/user/login',
        'POST',
        { mobile, password }
    );
};

export const menus = () => {
    return request(
        '/user/menus',
        'POST',
        {}
    );
};

export const isLogin = () => {
    return request(
        '/user/isLogin',
        'POST',
        {}
    );
}

export const getUserInfo = () => {
    return request(
        '/user/getUserInfo',
        'GET',
    );
}

export const logout = () => {
    return request(
        '/user/logout',
        'GET',
    );
}

export const modifyPassword = (originPassword, newPassword) => {
    return request(
        '/user/modifyPassword',
        'POST',
        { originPassword, newPassword }
    );
}

export const allUsers = (pageNum, pageSize) => {
    return request(
        '/user/allUsers',
        'POST',
        { pageNum, pageSize }
    );
}

export const register = (param) => {
    return request(
        '/user/register',
        'POST',
        param
    );
}

export const getUserInfoById = (userId) => {
    return request(
        '/user/getUserInfoById',
        'POST',
        { userId }
    );
}

export const updateUserInfo = (param) => {
    return request(
        '/user/updateUserInfo',
        'POST',
        param
    );
}

export const resetPassword = (param) => {
    return request(
        '/user/resetPassword',
        'POST',
        param
    );
}

export const deleteUser = (param) => {
    return request(
        '/user/deleteUser',
        'POST',
        param
    );
}