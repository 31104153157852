import React, { Component } from 'react';

class Iframe extends Component {

    componentDidMount() {
        this.iframe.addEventListener("load", this.getPages, false);
    }

    getPages = (e, times = 1) => {
        let pages = [];
        if (this.iframe) {
            pages = Array.from(
                this.iframe.contentDocument.querySelectorAll(".pdfpage")
            );
            if (pages.length || times >= 50) {
                this.props.onLoad();
                this.iframe.removeEventListener("load", this.getPages);
            } else {
                setTimeout(() => {
                    times++;
                    this.getPages(e, times);
                }, 1000);
            }
        }
    };

    render() {
        return (
            <iframe
                title={this.props.id}
                id={this.props.id}
                src={this.props.src}
                ref={node => (this.iframe = node)}
                style={{ visibility: 'hidden', width: 1200}}
            />
        );
    }
}

export default Iframe;