import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, Button, InputNumber, Table, message, Popconfirm } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { getByBatchNo, removeBillet, update, batchNoExist } from '../../../api/batch';
import { observer } from 'mobx-react';
import { observable } from 'mobx';


@observer
class ModifyBatch extends Component {

    @observable
    loading = false;

    @observable
    batchNo = '';

    @observable
    batch = null;

    @observable
    batchNoExist = false;

    componentDidMount() {
        let { batchNo } = this.props;
        this.batchNo = batchNo;
        this.fetch();
        this.show = this.props.show;
    }

    componentWillReceiveProps(nextProps) {
        if (this.batchNo !== nextProps.batchNo) {
            this.batchNo = nextProps.batchNo;
            this.fetch();
            this.show = this.props.show;
        }
    }

    fetch = async () => {
        if (this.batchNo && this.batchNo !== '') {
            try {
                this.loading = true;
                let resp = await getByBatchNo(this.batchNo);
                let { data } = resp;
                if (data.code === 0) {
                    this.batch = data.data;
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    remove = async (batchId, billetId) => {
        let resp = await removeBillet(batchId, billetId);
        let { data } = resp;
        if (data.code === 0) {
            this.batch = null;
            this.batchNo = '';
            let { modifyCancel } = this.props;
            modifyCancel && modifyCancel();
        }
    }

    setBatchNumber = (billet, number) => {
        for (var i = 0; i < this.batch.children.length; i++) {
            if (this.batch.children[i].billetId === billet.billetId) {
                this.batch.children[i].batchNumber = number;
                break;
            }
        }
    }

    setBatchNo = (e) => {
        this.batch.batchNo = e.target.value;
        if (this.batch.batchNo !== this.batchNo) {
            this.queryBatchNoExist(this.batch.batchNo);
        }
        else {
            this.batchNoExist = false;
        }
    }

    queryBatchNoExist = async (batchNo) => {
        let resp = await batchNoExist(batchNo);
        let { data } = resp;
        this.batchNoExist = (data.code === 0 && data.data);
    }


    submit = async () => {
        let { batch } = this;
        if (!batch.batchNo || batch.batchNo.trim() === '') {
            message.error('请输入轧钢批号');
            return;
        }
        for (let b in batch.children.target) {
            if (!b.batchNumber || b.batchNumber === 0) {
                message.error('支数不能为0');
                return;
            }
        }
        try {
            this.loading = true;
            let resp = await update(batch);
            let { data } = resp;
            if (data.code === 0) {
                this.batchNo = '';
                message.success('修改成功');
            }
            else {
                message.error('操作失败:' + data.data);
            }
        }
        finally {
            this.batch = null;
            this.batchNo = '';
            let { modifyCancel } = this.props;
            modifyCancel && modifyCancel();
            this.loading = false;
        }
    }


    render() {
        let { batch } = this;
        return (
            <div>
                {(batch && batch.children.length > 0) && (
                    <div>
                        <div className={styles.row}>
                            <div className={styles.required}>*</div>
                            <div className={styles.label}>轧钢批号:</div>
                            <div className={styles.batchNo}>
                                <Input
                                    maxLength={20}
                                    allowClear
                                    onChange={this.setBatchNo}
                                    autoFocus={true}
                                    value={this.batch.batchNo}
                                    placeholder='请输入轧钢批号'
                                />
                            </div>
                            {this.batchNoExist && (
                                <div className={styles.tips}>
                                    请注意：轧钢批号已经存在！修改批次时不允许此操作
                                </div>
                            )}
                        </div>
                        <div className={styles.row}>
                            <Table
                                locale={locale}
                                rowKey={record => record.billetId}
                                columns={this.columns}
                                size="small"
                                dataSource={this.batch.children}
                                pagination={false}
                            />
                        </div>
                        <div className={styles.btn}>
                            <Button
                                type='primary'
                                style={{ marginRight: '20px' }}
                                onClick={this.submit}
                                loading={this.loading}
                                disabled={this.loading || this.batchNoExist}
                            >
                                确定
                            </Button>
                            <Button
                                type='default'
                                onClick={() => {
                                    this.batch = null;
                                    this.batchNo = '';
                                    let { modifyCancel } = this.props;
                                    modifyCancel && modifyCancel();
                                }}
                            >
                                取消
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    columns = [
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
        },
        {
            key: 'acceptanceNumber',
            title: '已验收(支)',
            dataIndex: 'acceptanceNumber',
            align: 'center'
        },
        {
            key: 'leftNumber',
            title: '待组批(支)',
            dataIndex: 'leftNumber',
            align: 'center'
        },
        {
            key: 'batchNumber',
            title: '本批支数',
            align: 'center',
            render: (record) => {
                return (
                    <div style={{ width: '120px' }}>
                        <InputNumber
                            min={1}
                            step={1}
                            precision={0}
                            max={record.leftNumber + record.batchNumber}
                            defaultValue={record.batchNumber}
                            onChange={(value) => this.setBatchNumber(record, value)}
                        />
                    </div>
                );
            }
        },
        {
            key: 'carbon',
            title: '碳(C)',
            dataIndex: 'carbon',
            align: 'center'
        },
        {
            key: 'manganese',
            title: '锰(Mn)',
            dataIndex: 'manganese',
            align: 'center'
        },
        {
            key: 'silicon',
            title: '硅(Si)',
            dataIndex: 'silicon',
            align: 'center'
        },
        {
            key: 'phosphorus',
            title: '磷(P)',
            dataIndex: 'phosphorus',
            align: 'center'
        },
        {
            key: 'sulfur',
            title: '硫(S)',
            dataIndex: 'sulfur',
            align: 'center'
        },
        {
            key: 'vanadium',
            title: '钒(V)',
            dataIndex: 'vanadium',
            align: 'center'
        },
        {
            key: 'cep',
            title: 'ceq',
            dataIndex: 'cep',
            align: 'center'
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: '80px',
            render: (record) => {
                return (
                    <Popconfirm
                        title="移除操作将直接生效，确定要移除吗?"
                        onConfirm={() => this.remove(record.batchId, record.billetId)}
                        okText="是"
                        cancelText="否"
                    >
                        {this.batch.children.length > 1 && (
                            <div className={styles.remove}>
                                移除
                            </div>
                        )}
                    </Popconfirm>
                );
            }
        },
    ]
}

export default ModifyBatch;