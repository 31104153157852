import React, { Component, Fragment } from 'react';
import ComponentAnalysisSearch from './search';
import { billetList, insertBillet, delBillet, getBilletById, updateById, deliverBillet } from '../../api/billet';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Table, message, Row, Col, Popconfirm, Upload } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import AddComponentAnalysis from './add';
import ModifyComponentAnalysis from './modify';
import moment from 'moment';
import { getCookie, getEnv } from '../../utils/http';
import AuthLayer from '../../component/authLayer';

@observer
class ComponentAnalysis extends Component {

    @observable
    list = [];

    @observable
    newRecord = false;

    @observable
    loading = false;

    @observable
    addSubmitting = false;

    @observable
    modifyRecord = false;

    @observable
    modifyBillet = null;

    param;
    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;


    componentDidMount() {
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    queryList = async (param) => {
        try {
            this.loading = true;
            let resp = await billetList(param);
            let { data } = resp;
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            }
        }
        finally {
            this.loading = false;
        }
    }

    search = (param) => {
        this.param = param;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    addDrawerOpen = () => {
        this.newRecord = true;
    }

    addDrawerClose = (isClose) => {
        this.newRecord = isClose;
    }

    modifyDrawerOpen = (billetId) => {
        this.modifyRecord = true;
        this.getBillet(billetId);
    }

    modifyDrawerClose = (isClose) => {
        this.modifyRecord = isClose;
        this.modifyBillet = null;
    }

    getBillet = async (billetId) => {
        try {
            let resp = await getBilletById(billetId);
            let { data } = resp;
            if (data.code === 0 && data.data) {
                this.modifyBillet = data.data;
            }
        }
        catch (e) {
            message.error('请刷新重试');
        }
    }

    updateById = async (param) => {
        param.billetId = this.modifyBillet.billetId;
        try {
            let resp = await updateById(param);
            let { data } = resp;
            if (data.code === 0 && data.data) {
                this.modifyDrawerClose(false);
                message.success('修改成功');
                let { pageNum, pageSize } = this;
                this.queryList({ pageNum, pageSize, ...this.param });
            }
        }
        catch (e) {
            message.error('请刷新重试');
        }
    }

    insertBillet = async (param) => {
        try {
            this.addSubmitting = true;
            let resp = await insertBillet(param);
            let { data } = resp;
            if (data.code === 0) {
                message.success('添加成功');
                this.newRecord = false;
                let { pageNum, pageSize } = this;
                this.queryList({ pageNum, pageSize, ...this.param });
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.addSubmitting = false;
        }
    }


    del = async (billetId) => {
        let resp = await delBillet(billetId);
        let { data } = resp;
        if (data.code === 0 && data.data) {
            message.success('删除成功');
            let { pageNum, pageSize } = this;
            this.queryList({ pageNum, pageSize, ...this.param });
        }
    }

    deliver = async (billetId) => {
        let resp = await deliverBillet(billetId);
        let { data } = resp;
        if (data.code === 0 && data.data) {
            message.success('交付成功');
            let { pageNum, pageSize } = this;
            this.queryList({ pageNum, pageSize, ...this.param });
        }
    }

    getUploadProps = () => {
        let that = this;
        return (
            {
                name: 'file',
                action: getEnv() === 'DEV' ? `/billet/importBillet` : '/api/billet/importBillet',
                headers: {
                    authorization: `Bearer ${getCookie('token')}`,
                },
                onChange(info) {
                    if (info.file.status === 'uploading') {
                        that.loading = true;
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.response.msg}`)
                        let { pageSize, param } = that;
                        that.queryList({ pageNum: 1, pageSize, ...param });
                        that.loading = false;
                    } else if (info.file.status === 'error') {
                        that.loading = false;
                        message.error(`${info.file.name} 上传失败 ${info.file.response.msg}`);
                    }
                },
            }
        );
    }

    downloadTemplate = () => {
        window.open('/钢坯化学成分导入模板.xlsx')
    }

    render() {
        let { pageNum, pageSize, total, modifyBillet } = this;
        let excelProps = this.getUploadProps();
        return (
            <Fragment>
                <ComponentAnalysisSearch submit={this.search} />
                <div className={styles.tools}>
                    <AuthLayer>
                        <Button type='link' onClick={this.addDrawerOpen}>新增</Button>
                    </AuthLayer>
                    <AuthLayer>
                        <Upload
                            {...excelProps}
                            multiple={false}
                            accept={'xlsx'}
                            showUploadList={false}
                        >
                            <Button type='link'>导入数据</Button>
                        </Upload>
                    </AuthLayer>
                    <Button type='link' onClick={this.downloadTemplate}>下载模板</Button>
                </div>
                <AddComponentAnalysis
                    show={this.newRecord}
                    drawerOnClose={this.addDrawerClose}
                    insertBillet={this.insertBillet}
                    addSubmitting={this.addSubmitting}
                />
                <ModifyComponentAnalysis
                    show={this.modifyRecord}
                    drawerOnClose={this.modifyDrawerClose}
                    updateById={this.updateById}
                    billet={modifyBillet}
                />
                <Table
                    rowKey={record => record.billetId}
                    columns={this.columns}
                    dataSource={this.list}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            let { pageNum, pageSize } = this;
                            this.queryList({ pageNum, pageSize, ...this.param });
                        }
                    }}
                    onChange={this.onTableChange}
                />
            </Fragment>

        );
    }
    columns = [
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
        },
        {
            key: 'grade',
            title: '牌号',
            dataIndex: 'grade',
        },
        {
            key: 'carbon',
            title: '碳(C)',
            dataIndex: 'carbon',
            align: 'center'
        },
        {
            key: 'sulfur',
            title: '硫(S)',
            dataIndex: 'sulfur',
            align: 'center'
        },
        {
            key: 'manganese',
            title: '锰(Mn)',
            dataIndex: 'manganese',
            align: 'center'
        },
        {
            key: 'phosphorus',
            title: '磷(P)',
            dataIndex: 'phosphorus',
            align: 'center'
        },
        {
            key: 'silicon',
            title: '硅(Si)',
            dataIndex: 'silicon',
            align: 'center'
        },
        {
            key: 'vanadium',
            title: '钒(V)',
            dataIndex: 'vanadium',
            align: 'center'
        },
        {
            key: 'cep',
            title: 'ceq',
            dataIndex: 'cep',
            align: 'center'
        },
        {
            key: 'deliverNumber',
            title: '交付数(支)',
            dataIndex: 'deliverNumber',
            align: 'center'
        },
        {
            key: 'analysisTime',
            title: '化验时间',
            dataIndex: 'analysisTime',
            align: 'center',
            render: (record) => {
                return (
                    <div>{moment(record).format('YYYY-MM-DD HH:mm')}</div>
                );
            }
        },
        {
            key: 'analysisEmployee',
            title: '化验人',
            dataIndex: 'analysisEmployee',
            align: 'center'
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            render: (record) => {
                return (
                    <AuthLayer>
                        {record.deliver === 0 ? (
                            <Row gutter={16}>
                                <Col
                                    span={8}
                                    className={styles.modify}
                                >
                                    <Popconfirm
                                        title="交付后将无法删除，确定吗?"
                                        onConfirm={() => this.deliver(record.billetId)}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        交付
                                    </Popconfirm>
                                </Col>
                                <Col
                                    span={8}
                                    className={styles.modify}
                                    onClick={() => this.modifyDrawerOpen(record.billetId)}
                                >
                                    修改
                                    </Col>
                                <Col
                                    span={8}
                                    className={styles.del}
                                >
                                    <Popconfirm
                                        title="确定要删除吗?"
                                        onConfirm={() => this.del(record.billetId)}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        删除
                                    </Popconfirm>
                                </Col>
                            </Row>
                        ) : (
                                <Row>
                                    <Col
                                        span={24}
                                        className={styles.modify}
                                        onClick={() => this.modifyDrawerOpen(record.billetId)}
                                    >
                                        修改
                                    </Col>
                                </Row>
                            )}
                    </AuthLayer>
                );
            }
        },
    ]
}

export default ComponentAnalysis;