import { request } from '../utils/http';

export const stockList = (param) => {
    return request(
        '/stock/stocks',
        'POST',
        param
    );
};

export const getStockDetailByBatchNo = (batchNo) => {
    return request(
        '/stock/getStockDetailByBatchNo',
        'POST',
        {batchNo}
    );
};

export const getStockById = (stockId) => {
    return request(
        '/stock/getStockById',
        'POST',
        {stockId}
    );
};

export const insertStock = (param) => {
    return request(
        '/stock/insert',
        'POST',
        param
    );
};

export const updateStock = (param) => {
    return request(
        '/stock/update',
        'POST',
        param
    );
};

export const deleteStock = (stockId) => {
    return request(
        '/stock/delStock',
        'POST',
        {stockId}
    );
};

export const warrantySheet = (stockId) => {
    return request(
        '/warranty/sheet',
        'POST',
        {stockId}
    );
};

export const getProductLeftNumberNotZeroBatchNo = () => {
    return request(
        '/stock/getProductLeftNumberNotZeroBatchNo',
        'GET',
    );
};

export const uploadWarrantyImage = (formData) => {
    return request(
        '/file/uploadWarranty',
        'POST',
        formData
    );
}