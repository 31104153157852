import React, { Component, Fragment } from 'react';
import styles from './index.module.scss';
import { login, isLogin } from '../../api/user';
import { message, Button, Input, Icon, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const LINK_BOX = [
    {
        link: 'http://www.xhgt.net',
        name: '兴华财富'
    },
    {
        link: 'https://www.zlgx.com',
        name: '中联钢信'
    },
    {
        link: 'https://trade.zlgx.com',
        name: '钢材交易'
    },
    {
        link: 'https://ore.zlgx.com',
        name: '大宗交易'
    },
    {
        link: 'https://log.zlgx.com',
        name: '物流货运'
    },
    {
        link: 'https://wms.zlgx.com/store/#/',
        name: '货物仓储'
    },
    {
        link: 'https://fin.zlgx.com',
        name: '金融服务'
    },
    {
        link: 'https://oa.zlgx.com',
        name: '协同办公'
    },
    {
        link: 'https://bid.zlgx.com',
        name: '招采专区'
    }
]

@observer
class Login extends Component {

    @observable mobile;
    @observable password;
    rememberMe = true;
    @observable loading = false;

    componentDidMount() {
        const userLoginMobile = localStorage.getItem("userLoginMobile");
        const userLoginPassword = localStorage.getItem("userLoginPassword");
        if (userLoginMobile && userLoginPassword && userLoginMobile !== '' && userLoginPassword !== '') {
            this.mobile = userLoginMobile;
            this.password = userLoginPassword;
        }
        this.isLogin();
    }

    isLogin = async () => {
        let response = await isLogin();
        let { data } = response;
        if (data.data) {
            this.props.history.push('/home');
        }
    }

    onMobileChange = (e) => {
        this.mobile = e.target.value;
    }

    onPasswordChange = (e) => {
        this.password = e.target.value;
    }

    submit = async () => {
        if (!this.mobile) {
            message.error('手机号码不能为空');
            return;
        }
        if (!(/^1[3456789]\d{9}$/.test(this.mobile))) {
            message.error('请输入正确的手机号码');
            return;
        }
        if (!this.password) {
            message.error('密码不能为空');
            return;
        }
        try {
            this.loading = true;
            let response = await login(this.mobile, this.password);
            let { data } = response;
            let { code } = data;
            if (code === 0 && data.data) {
                const d = new Date();
                d.setTime(d.getTime() + (4 * 60 * 60 * 1000));
                const expires = "expires=" + d.toUTCString();
                document.cookie = "token=" + data.data + "; " + expires;
                message.success('登录成功');
                if (this.rememberMe) {
                    localStorage.setItem("userLoginMobile", this.mobile);
                    localStorage.setItem("userLoginPassword", this.password);
                }
                window.location.href = ('/home');
            }
            else {
                message.error('用户名或密码错误');
            }
        }
        catch (e) {
            message.error('系统错误');
        }
        finally {
            this.loading = false;
        }
    }

    onEnterPress = (e) => {
        if (e.which === 13) {
            this.submit();
        }
    }

    onRememberMeChange = (e) => {
        this.rememberMe = e.target.checked;
        if (this.rememberMe === false) {
            localStorage.removeItem("userLoginMobile");
            localStorage.removeItem("userLoginPassword");
        }
    }

    render() {
        return (
            <Fragment>
                <div className={styles.login}>
                    <img
                        className={styles.background}
                        src='/background.jpg'
                        alt='background'
                        style={{
                            width: '100%',
                            height: 'auto',
                            minHeight: 500
                        }}
                    />
                    <div className={styles.name}>
                        <img
                            src='/logo.png'
                            alt='logo'
                            style={{
                                width: 'auto',
                                height: 24,
                                marginRight: 12
                            }}
                        />
                        <span className={styles.title}>兴华钢铁质保单自助管理系统</span>
                    </div>
                    <div className={styles.loginBox}>
                        <div className={styles.loginBoxTitle}>
                            用户登录
                        </div>
                        <div className={styles.form}>
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                className={styles.data}
                                value={this.mobile}
                                placeholder='请输入手机号码'
                                onChange={this.onMobileChange}
                            />
                        </div>
                        <div className={styles.form}>
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                className={styles.data}
                                value={this.password}
                                placeholder='请输入您的密码'
                                onChange={this.onPasswordChange}
                                onKeyPress={this.onEnterPress}
                            />
                        </div>
                        <div className={styles.remember}>
                            <Checkbox onClick={this.onRememberMeChange} defaultChecked={true}>
                                记住用户名和密码
                            </Checkbox>
                        </div>
                        <div className={styles.form}>
                            <Button
                                type='primary'
                                loading={this.loading}
                                size='large'
                                className={styles.btn}
                                onClick={this.submit}
                            >
                                登录
                            </Button>
                        </div>
                    </div>
                    <div className={styles.foot}>
                        <div className={styles.lineBox}>
                            <div className={styles.line}></div>
                            <div className={styles.footTitle}>友情链接</div>
                            <div className={styles.line}></div>
                        </div>
                        <div className={styles.linkBox}>
                            {LINK_BOX.map((d, i) => {
                                return (
                                    <a
                                        key={i}
                                        className={styles.link}
                                        target="_blank"
                                        href={d.link}
                                        rel="noopener noreferrer"
                                    >
                                        {d.name}
                                    </a>
                                );
                            })}
                        </div>
                        <div className={styles.support}>{`技术支持: 大数金科网络技术有限公司`}</div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Login;