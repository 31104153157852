import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { getGradeList, addGrade, delGrade, getById, update } from '../../api/grade';
import { message, Table, Popconfirm, Button, Modal } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import AuthLayer from '../../component/authLayer';
import AddGrade from './add';
import ModifyGrade from './modify';

@observer
class Grade extends Component {

    @observable gradeList = [];
    @observable loading = false;
    @observable addVisiable = false;
    @observable modifyVisiable = false;
    @observable modifyGrade = {};

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        try {
            this.loading = true;
            let response = await getGradeList();
            let { data } = response;
            if (data.code === 0) {
                this.gradeList = data.data;
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    insertGrade = async (params) => {
        try {
            this.loading = true;
            let response = await addGrade(params);
            let { data } = response;
            if (data.code === 0) {
                message.success('添加成功');
                this.addVisiable = false;
                this.fetch();
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    delete = async (gradeId) => {
        try {
            this.loading = true;
            let response = await delGrade({ gradeId });
            let { data } = response;
            if (data.code === 0) {
                message.success('删除成功');
                this.fetch();
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    queryGrade = async (gradeId) => {
        try {
            this.loading = true;
            const resp = await getById({ gradeId });
            const { data } = resp;
            if (data.code === 0) {
                this.modifyGrade = data.data;
                this.modifyVisiable = true;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    updateGrade = async (params) => {
        try {
            const { modifyGrade } = this;
            this.loading = true;
            const resp = await update({
                gradeId: modifyGrade.gradeId,
                ...params
            });
            const { data } = resp;
            if (data.code === 0) {
                message.success('修改成功');
                this.onModifyCanceled();
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    onAddClicked = () => {
        this.addVisiable = true;
    }

    onAddCanceled = () => {
        this.addVisiable = false;
    }

    onModifyCanceled = () => {
        this.modifyVisiable = false;
        this.modifyGrade = {};
    }

    render() {
        return (
            <Fragment>
                <div style={{ marginBottom: 20 }}>
                    <Button
                        type='primary'
                        onClick={this.onAddClicked}
                    >
                        新增牌号
                    </Button>
                </div>
                <Modal
                    title='新增牌号'
                    width={500}
                    visible={this.addVisiable}
                    onCancel={this.onAddCanceled}
                    footer={null}
                >
                    <AddGrade
                        // @ts-ignore
                        insertGrade={this.insertGrade} />
                </Modal>
                <Modal
                    title='修改牌号'
                    width={500}
                    visible={this.modifyVisiable}
                    onCancel={this.onModifyCanceled}
                    footer={null}
                >
                    <ModifyGrade
                        // @ts-ignore
                        modifyGrade={this.updateGrade}
                        grade={this.modifyGrade}
                    />
                </Modal>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginBottom: 20,
                    minHeight: 200,
                }}>
                    <Table
                        rowKey={record => record.gradeId}
                        // @ts-ignore
                        columns={this.columns}
                        dataSource={this.gradeList}
                        loading={this.loading}
                    />
                </div>
            </Fragment>
        );
    }

    columns = [
        {
            key: 'grade',
            title: '牌号',
            dataIndex: 'grade',
            width: 300,
        },
        {
            key: 'prodName',
            title: '产品名称',
            dataIndex: 'prodName',
            width: 300,
        },
        {
            key: 'standard',
            title: '检查标准',
            dataIndex: 'standard',
            width: 300,
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 200,
            render: (record) => {
                return (
                    <AuthLayer>
                        <span
                            className={styles.modifyBtn}
                            onClick={() => this.queryGrade(record.gradeId)}
                        >
                            修改
                        </span>
                        <span style={{ color: '#FF0033', marginLeft: 30 }} >
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => this.delete(record.gradeId)}
                                okText="是"
                                cancelText="否"
                            >
                                删除
                            </Popconfirm>
                        </span>
                    </AuthLayer>
                );
            }
        },
    ]

}

export default Grade;