import { request } from '../utils/http';

export const insertProduct = (param) => {
    return request(
        '/product/insert',
        'POST',
        param
    );
};


export const productList = (param) => {
    return request(
        '/product/list',
        'POST',
        param
    );
};

export const productExist = (batchNo, prodName) => {
    return request(
        '/product/productExist',
        'POST',
        { batchNo, prodName }
    );
};

export const billetProductExist = (batchNo) => {
    return request(
        '/product/batchNoForBilletProductAvailable',
        'POST',
        { batchNo }
    );
};

export const updateProduct = (param) => {
    return request(
        '/product/update',
        'POST',
        param
    );
};

export const getProductById = (prodId) => {
    return request(
        '/product/getById',
        'POST',
        { prodId }
    );
};

export const delProduct = (batchNo) => {
    return request(
        '/product/del',
        'POST',
        { batchNo }
    );
};

export const printProduct = (batchNo) => {
    return request(
        '/product/print',
        'POST',
        { batchNo }
    );
};

export const getUnDeliverMech = (prodName) => {
    return request(
        '/product/getUnDeliverMech',
        'POST',
        { prodName }
    );
};

export const getProductModifyRecordList = (param) => {
    return request(
        '/product/getProductModifyRecordList',
        'POST',
        param
    );
};