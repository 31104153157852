import { request } from '../utils/http';

export const getSysConfigList = () => {
    return request(
        '/sys/sysConfigList',
        'POST',
    );
};

export const updateSysConfig = (param) => {
    return request(
        '/sys/updateConfig',
        'POST',
        param
    );
};