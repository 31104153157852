import React, { Component, Fragment } from 'react';
import BatchSearch from './search';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './index.module.scss';
import { Button, Modal } from 'antd';
import { batchList, delBatch } from '../../api/batch';
import BatchTable from './table';
import ModifyBatch from './modify';
import { getEnv } from '../../utils/http';
import axios from 'axios';


@observer
class Batch extends Component {

    @observable
    list = [];

    @observable
    loading = false;

    param;

    @observable
    modifyShow = false;

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    @observable
    currentBatchNo = '';

    componentDidMount() {
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    queryList = async (param) => {
        try {
            this.loading = true;
            let resp = await batchList(param);
            let { data } = resp;
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            }
        }
        finally {
            this.loading = false;
        }
    }

    submit = (param) => {
        this.param = param;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    del = async (batchNo) => {
        try {
            this.loading = true;
            let resp = await delBatch(batchNo);
            let { data } = resp;
            if (data.code === 0 && data.data) {
                let { pageNum, pageSize } = this;
                this.queryList({ pageNum, pageSize, ...this.param });
            }
        }
        finally {
            this.loading = false;
        }
    }

    onShowSizeChange = (current, size) => {
        this.pageNum = current;
        this.pageSize = size;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    modify = (batchNo) => {
        this.modifyShow = true;
        this.currentBatchNo = batchNo;
    }

    modifyCancel = () => {
        this.currentBatchNo = '';
        this.modifyShow = false;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    export = async () => {
        this.loading = true;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/batch/exportBatchList` : '/api/batch/exportBatchList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...this.param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = "组批台账.xlsx";
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    render() {
        let { pageNum, pageSize, total } = this;
        return (
            <Fragment>
                <BatchSearch
                    // @ts-ignore
                    submit={this.submit} />
                <div className={styles.tools}>
                    <Button type='link' onClick={this.export}>导出数据</Button>
                </div>
                <Modal
                    title='修改组批'
                    width={950}
                    visible={this.modifyShow}
                    onCancel={() => this.modifyCancel()}
                    footer={null}
                >
                    <ModifyBatch
                        batchNo={this.currentBatchNo}
                        modifyCancel={this.modifyCancel}
                    />
                </Modal>
                <BatchTable
                    loading={this.loading}
                    dataSource={this.list}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    total={total}
                    onShowSizeChange={this.onShowSizeChange}
                    onTableChange={this.onTableChange}
                    del={this.del}
                    modify={this.modify}
                />
            </Fragment>
        );
    }



}

export default Batch;