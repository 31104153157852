// @ts-nocheck
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Input, Modal, Form, InputNumber, Select, DatePicker, Spin } from 'antd';
import styles from './index.module.scss';
import moment from 'moment';
import { getProductById } from '../../../api/product';

const { Option } = Select;

const boundOptions = [
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
    { label: '⌀45', value: '45' },
    { label: '⌀48', value: '48' },
    { label: '⌀50', value: '50' },
    { label: '⌀55', value: '55' },
    { label: '⌀56', value: '56' },
    { label: '⌀60', value: '60' },
];

const deformedOptions = [
    { label: '⌀12', value: '12' },
    { label: '⌀14', value: '14' },
    { label: '⌀16', value: '16' },
    { label: '⌀18', value: '18' },
    { label: '⌀20', value: '20' },
    { label: '⌀22', value: '22' },
    { label: '⌀25', value: '25' },
    { label: '⌀28', value: '28' },
    { label: '⌀30', value: '30' },
    { label: '⌀32', value: '32' },
    { label: '⌀34', value: '34' },
    { label: '⌀35', value: '35' },
    { label: '⌀36', value: '36' },
    { label: '⌀38', value: '38' },
    { label: '⌀40', value: '40' },
    { label: '⌀42', value: '42' },
];

@observer
class ModifyProduct extends Component {

    @observable
    prodName = '圆钢';

    @observable
    show = false;

    @observable
    loading = false;

    @observable
    submitting = false;

    @observable
    prodId = '';

    @observable
    batchNo = '';

    @observable
    prodTime = null;

    @observable
    shift = '';

    @observable
    values = {};

    @observable
    shiftList = [];

    @observable
    shiftListLoading = false;



    componentDidMount() {
        this.prodName = this.props.prodName;
        this.prodId = this.props.prodId;
        this.shiftList = this.props.shiftList;
    }

    componentWillReceiveProps(nextProps) {
        if (this.prodName !== nextProps.prodName) {
            this.prodName = nextProps.prodName;
        }
        if (this.shiftList !== nextProps.shiftList) {
            this.shiftList = nextProps.shiftList;
        }
        if (this.prodId !== nextProps.prodId) {
            this.prodId = nextProps.prodId;
            this.fetchProduct();
        }
    }

    fetchProduct = async () => {
        if (this.prodId && this.prodId !== '') {
            try {
                this.loading = true;
                let resp = await getProductById(this.prodId);
                let { data } = resp;
                if (data.code === 0) {
                    this.batchNo = data.data.batchNo;
                    this.prodTime = moment(data.data.prodTime);
                    this.shift = data.data.shift;
                    this.values = data.data;
                }
            }
            finally {
                this.loading = false;
            }
        }
    }


    onClose = () => {
        this.resetValues();
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose();
    }

    resetValues = () => {
        this.batchNo = '';
        this.prodId = '';
        this.prodTime = null;
        this.shift = '';
        this.values = {};
        let { resetModifyValues } = this.props;
        resetModifyValues && resetModifyValues();
        this.props.form.resetFields();
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.prodId = this.prodId;
                values.batchNo = this.batchNo;
                values.prodTime = moment(this.prodTime).format("YYYY-MM-DD");
                values.shift = this.shift;
                values.prodName = this.prodName;
                let { modifyProduct } = this.props;
                modifyProduct(values);
                this.props.form.resetFields();
                this.resetValues();
            }
        });
    };

    onBatchNoChange = (e) => {
        this.batchNo = e.target.value;
    }

    onProdTimeChange = (date, dateString) => {
        this.prodTime = date
    }

    onShiftChange = (value) => {
        this.shift = value;
    }


    render() {
        let { show } = this.props;
        let { getFieldDecorator } = this.props.form;
        let { values } = this;
        return (
            <Modal
                title={`修改${this.prodName}成品记录`}
                onCancel={this.onClose}
                visible={show}
                width={1250}
                footer={null}
            >
                <div className={styles.addContent}>
                    <Spin spinning={this.loading}>
                        <div className={styles.basicRow}>
                            <div className={styles.required}>*</div>
                            <div className={styles.basicLabel}>轧钢批号:</div>
                            <div className={styles.basicValue}>
                                <Input
                                    style={{ width: 210 }}
                                    maxLength={20}
                                    value={this.batchNo}
                                    disabled={true}
                                />
                            </div>
                            <div className={styles.required}>*</div>
                            <div className={styles.basicLabel}>生产日期:</div>
                            <div className={styles.basicValue}>
                                <DatePicker
                                    style={{ width: 210 }}
                                    placeholder="请选择日期"
                                    onChange={this.onProdTimeChange}
                                    value={this.prodTime}
                                    allowClear={false}
                                />
                            </div>

                            <div className={styles.required}>*</div>
                            <div className={styles.basicLabel}>班次:</div>
                            <div className={styles.basicValue}>
                                <Select
                                    value={this.shift}
                                    style={{ width: 210 }}
                                    loading={this.shiftListLoading}
                                    onChange={this.onShiftChange}
                                >
                                    {(this.shiftList) && (
                                        this.shiftList.map((item, index) => {
                                            return (
                                                <Option key={index} value={item}>{item}</Option>
                                            );
                                        })
                                    )}
                                </Select>
                            </div>
                        </div>
                        <Form onSubmit={this.handleSubmit}>
                            <div className={styles.row}>
                                <div className={styles.raw}>
                                    <div className={styles.subTitle}>投料量</div>
                                    <div className={styles.subRow}>
                                        <div className={styles.col}>
                                            <Form.Item label='支数'>
                                                {getFieldDecorator('rawNumber',
                                                    {
                                                        rules: [{ required: false, message: '请填写' }],
                                                        initialValue: values.rawNumber
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={1}
                                                        precision={0}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='重量(t)'>
                                                {getFieldDecorator('rawWeight',
                                                    {
                                                        rules: [{ required: false, message: '请填写' }],
                                                        initialValue: values.rawWeight
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={0.001}
                                                        precision={3}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.prod}>
                                    <div className={styles.subTitle}>成品</div>
                                    <div className={styles.subRow}>
                                        <div className={styles.col}>
                                            <Form.Item label='规格'>
                                                {getFieldDecorator('spec',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.spec
                                                    })
                                                    (<Select
                                                        style={{ width: '100px' }}
                                                        allowClear={true}
                                                        showSearch
                                                    >
                                                        {this.prodName === '圆钢' && (
                                                            boundOptions.map((spec, index) => {
                                                                return (
                                                                    <Option key={index} value={spec.value}>{spec.label}</Option>
                                                                );
                                                            })
                                                        )}
                                                        {this.prodName === '螺纹钢' && (
                                                            deformedOptions.map((spec, index) => {
                                                                return (
                                                                    <Option key={index} value={spec.value}>{spec.label}</Option>
                                                                );
                                                            })
                                                        )}
                                                    </Select>)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='长度(m)'>
                                                {getFieldDecorator('length',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.length
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={100}
                                                        step={1}
                                                        precision={2}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='捆数'>
                                                {getFieldDecorator('prodNumber',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.prodNumber
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={1}
                                                        precision={0}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='重量(t)'>
                                                {getFieldDecorator('prodWeight',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.prodWeight
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={0.001}
                                                        precision={3}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.prod}>
                                    <div className={styles.subTitle}></div>
                                    <div className={styles.subRow}>
                                        <div className={styles.col}>
                                            <Form.Item label='检验废(t)'>
                                                {getFieldDecorator('unqualified',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.unqualified
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={0.001}
                                                        precision={3}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='待处理(t)'>
                                                {getFieldDecorator('pendingDisposal',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.pendingDisposal
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={0.001}
                                                        precision={3}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.prodRight}>
                                    <div className={styles.subTitle}>乱尺</div>
                                    <div className={styles.subRow}>
                                        <div className={styles.col}>
                                            <Form.Item label='重量(t)'>
                                                {getFieldDecorator('disorderlyFeetWeight',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.disorderlyFeetWeight
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={0.001}
                                                        precision={3}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                        <div className={styles.col}>
                                            <Form.Item label='捆数'>
                                                {getFieldDecorator('disorderlyFeetNumber',
                                                    {
                                                        rules: [{ required: true, message: '请填写' }],
                                                        initialValue: values.disorderlyFeetNumber
                                                    })
                                                    (<InputNumber
                                                        style={{ width: '100px' }}
                                                        max={1000}
                                                        step={1}
                                                        precision={0}
                                                    />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.btnRow}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: 80 }}
                                    disabled={this.loading}
                                    loading={this.loading}
                                >
                                    确定
                                </Button>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        );
    }

}

export default Form.create()(ModifyProduct);;