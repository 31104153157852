import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
// @ts-ignore
import styles from './index.module.scss';
import { Button, message, Pagination } from 'antd';
import StockSearch from './search';
import StockTable from './table';
import AddStock from './add';
import ModifyStock from './modify';
import { insertStock, stockList, deleteStock, updateStock, uploadWarrantyImage } from '../../api/stock';
import ReactDOM from 'react-dom';
import Iframe from './iframe';
import html2canvas from 'html2canvas';
import AuthLayer from '../../component/authLayer';

const getIframeContainer = () => {
    const ic = document.getElementById("iframeContainer");
    if (!ic) {
        const iframeContainer = document.createElement("div");
        iframeContainer.id = "iframeContainer";
        iframeContainer.style.visibility = "hidden";
        document.body.appendChild(iframeContainer);
        return iframeContainer;
    }
    return ic;
};


@observer
class Stock extends Component {

    // 分页
    @observable pageNum = 1;
    @observable pageSize = 10;
    @observable total = 0;
    // 加载状态
    @observable loading = false;
    // 添加删除对话框控制
    @observable showAdd = false;
    @observable showModify = false;
    @observable modifyStockId = '';
    // 出库记录列表
    @observable stocks = [];
    // 质保单数据
    @observable warrantySockId = '';
    @observable id = '';
    // 搜索参数
    param = {};

    componentDidMount() {
        this.queryStockList();
    }

    addDrawerOpen = () => {
        this.showAdd = true;
    }

    modifyDrawerOpen = (stockId) => {
        this.showModify = true;
        this.modifyStockId = stockId;
    }

    drawerOnClose = () => {
        this.showAdd = false;
        this.showModify = false;
        this.modifyStockId = '';
        this.warrantySockId = '';
    }

    /**
     * 新建出库记录
     */
    insert = async (param) => {
        try {
            this.loading = true;
            let response = await insertStock(param);
            let { data } = response;
            if (data.code === 0) {
                message.success('添加成功');
                this.id = '';
                this.id = data.data;
                this.queryStockList();
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    /**
     * 更新出库记录
     */
    update = async (param) => {
        try {
            this.loading = true;
            let response = await updateStock(param);
            let { data } = response;
            if (data.code === 0) {
                this.id = '';
                this.id = param.stockId;
                message.success('修改成功');
                this.queryStockList();
            }
            else {
                message.error(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    reGenerateWarranty = (stockId) => {
        this.id = '';
        this.id = stockId;
    }

    /**
     * 删除出库记录
     */
    del = async (stockId) => {
        let response = await deleteStock(stockId);
        let { data } = response;
        if (data.code === 0 && data.data === true) {
            message.success("删除成功");
            this.queryStockList();
        }
        else {
            message.error("删除失败");
        }
    }

    /**
     * 查询出库记录列表
     */
    queryStockList = async () => {
        let { pageNum, pageSize } = this;
        try {
            this.loading = true;
            let response = await stockList({ pageNum, pageSize, ...this.param });
            let { data } = response;
            if (data.code === 0) {
                this.pageNum = data.data.pageNum;
                this.total = data.data.total;
                this.stocks = data.data.list;
            }
            else {
                message.error("查询失败");
            }
        }
        finally {
            this.loading = false;
        }
    }

    onSelectWarranty = (stockId) => {
        this.warrantySockId = stockId;
        let { history } = this.props;
        history.push(`/warranty/${stockId}`);
    }


    searchSubmit = (param) => {
        this.pageNum = 1;
        this.param = param;
        this.queryStockList();
    }

    onShowSizeChange = (current, size) => {
        this.pageNum = current;
        this.pageSize = size;
        this.queryStockList();
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination;
        this.queryStockList();
    }

    onloadCallback = () => {
        if (this.id && this.id !== '') {
            const iframeContainer = getIframeContainer();
            const iframeId = `iframe_${this.id}`;
            message.warn('上传中，请稍候')
            setTimeout(()=>{

                this.createAndUpload(iframeId).then(
                    () => {
                        ReactDOM.unmountComponentAtNode(iframeContainer);
                        this.loading = false;
                    },
                    errMsg => {
                        ReactDOM.unmountComponentAtNode(iframeContainer);
                        console.error(errMsg);
                    }
                );
            },800)
        
        }
    };

    createAndUpload = iframeId => {
        return new Promise((resolve, reject) => {
            let pages = Array.from(
                // @ts-ignore
                document.getElementById(iframeId).contentDocument.querySelectorAll(".pdfpage")
            );
            const pagesLen = pages.length;
            if (!pagesLen) {
                message.error(`未找到id为${iframeId}的数据，请联系管理员`)
                reject("尚未生成质保单");
            }
            // 将每个元素作为一个页面处理
            pages.forEach((element, index) => {
                const newCanvas = document.createElement("canvas");
                const dom_width = parseInt(window.getComputedStyle(element).width);
                const dom_height = parseInt(window.getComputedStyle(element).height);
                newCanvas.width = dom_width * 2 * window.devicePixelRatio;
                newCanvas.height = dom_height * 2 * window.devicePixelRatio;
                newCanvas.style.width = dom_width + "px";
                newCanvas.style.height = dom_height + "px";
                const context = newCanvas.getContext("2d");
                context.scale(2, 2);
                html2canvas(element, { canvas: newCanvas }).then((canvas) => {
                    const imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                    const base64ToBlob = (code) => {
                        let parts = code.split(';base64,');
                        let contentType = parts[0].split(':')[1];
                        let raw = window.atob(parts[1]);
                        let rawLength = raw.length;
                        let uInt8Array = new Uint8Array(rawLength);
                        for (let i = 0; i < rawLength; ++i) {
                            uInt8Array[i] = raw.charCodeAt(i);
                        }
                        return new Blob([uInt8Array], { type: contentType });
                    };
                    const blob = base64ToBlob(imgUri);
                    const formData = new FormData();
                    formData.append("file", blob);
                    formData.append("stockId", this.id);
                    formData.append("fileName", `兴华钢铁质量保证书${index}.png`);
                    this.upload(formData);
                });
            });
        });
    };

    upload = async formData => {
        try {
            const resp = await uploadWarrantyImage(formData);
            const data = { resp };
            if (data.resp.data.code === 0) {
                message.success('质保单上传成功!');
            } else {
                message.error(data.msg);
            }
        }
        catch (e) {
            message.error('质保单生成失败');
        }
    }

    render() {
        let { pageNum, pageSize, total } = this;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                position: 'relative'
            }}>
                <StockSearch
                    // @ts-ignore
                    searchSubmit={this.searchSubmit} />
                <div className={styles.tools}>
                    <AuthLayer>
                        <Button type='link' onClick={this.addDrawerOpen}>新增</Button>
                    </AuthLayer>
                </div>
                <AddStock
                    // @ts-ignore
                    show={this.showAdd}
                    drawerOnClose={this.drawerOnClose}
                    insertStock={this.insert}
                    loading={this.loading}
                />
                <ModifyStock
                    // @ts-ignore
                    show={this.showModify}
                    drawerOnClose={this.drawerOnClose}
                    loading={this.loading}
                    modifyStockId={this.modifyStockId}
                    updateStock={this.update}
                />
                <StockTable
                    dataSource={this.stocks}
                    delStock={this.del}
                    dataLoading={this.loading}
                    onModify={this.modifyDrawerOpen}
                    selectWarranty={this.onSelectWarranty}
                    reGenerateWarranty={this.reGenerateWarranty}
                />
                {this.total > 0 && (
                    <div className={styles.pagination}>
                        <Pagination
                            current={pageNum}
                            pageSize={pageSize}
                            total={total}
                            showSizeChanger={true}
                            showTotal={(total) => { return `共${total}条` }}
                            onShowSizeChange={this.onShowSizeChange}
                            onChange={this.onTableChange}
                        />
                    </div>
                )}
                <Iframe
                    id={`iframe_${this.id}`}
                    src={`/uploadWarrantyImage/${this.id}`}
                    onLoad={this.onloadCallback}
                    key={`iframe_${this.id}`}
                />
            </div>
        );
    }

}

export default Stock;