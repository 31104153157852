import React, { Component } from 'react';
import styles from './index.module.scss';
import moment from 'moment';

class DeformedWarranty extends Component {


    render() {
        let { warranty } = this.props;
        return (
            <div className={styles.billetOutter}>
                <div className={`${'pdfpage'} ${styles['warrantyBox']}`} id={'w' + warranty.warrantySheetNo}>
                    <img
                        src='/cqc.png'
                        alt='cqc'
                        style={{
                            position: 'absolute',
                            right: 285,
                            top: 20,
                            width: 80,
                            height: 60,
                        }}
                    />
                    <img
                        src='/cnas.png'
                        alt='cnas'
                        style={{
                            position: 'absolute',
                            right: 180,
                            top: 20,
                            width: 80,
                            height: 60,
                        }}
                    />
                    <img
                        src='/qs.png'
                        alt='qs'
                        style={{
                            position: 'absolute',
                            right: 100,
                            top: 22,
                            width: 48,
                            height: 65,
                        }}
                    />
                    <img
                        src='/sign1.png'
                        alt='sign'
                        style={{
                            position: 'absolute',
                            right: 60,
                            bottom: 40,
                            width: 200,
                            height: 140,
                            zIndex: 9999,
                            opacity: 0.85
                        }}
                    />
                    <div className={styles.companyName}>{warranty.companyName}</div>
                    <div className={styles.productionLicenseNo} >{`生产许可证号: ${warranty.productionLicenseNo}`}</div>
                    <div className={styles.title}>产品质量证明书</div>
                    <div className={styles.subTitle}>
                        <div style={{ width: 372, paddingLeft: 25 }}>{`证书编号: ${warranty.warrantySheetNo}`}</div>
                        <div style={{ width: 372, textAlign: 'center' }}>本企业通过ISO9001质量管理体系认证</div>
                        <div style={{ width: 372, textAlign: 'center' }}>8.2.4/ZL(JL)-10</div>
                    </div>
                    <div className={styles.table}>
                        <div className={styles.row}>
                            <div className={styles.td} style={{ width: 186 }}>产品名称</div>
                            <div className={styles.td} style={{ width: 240 }}>{warranty.productName}</div>
                            <div className={styles.td} style={{ width: 117 }}>收货单位</div>
                            <div className={styles.td} style={{ width: 348 }}>{warranty.consignee}</div>
                            <div className={styles.td} style={{ width: 90 }}>车牌号</div>
                            <div className={styles.td} style={{ width: 135, borderRight: 0 }}>{warranty.licensePlate}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.td} style={{ width: 186 }}>检查依据</div>
                            <div className={styles.td} style={{ width: 240 }}>{warranty.inspectionStandard}</div>
                            <div className={styles.td} style={{ width: 117 }}>交货状态</div>
                            <div className={styles.td} style={{ width: 123 }}>{warranty.deliverCondition}</div>
                            <div className={styles.td} style={{ width: 109 }}>检验结果</div>
                            <div className={styles.td} style={{ width: 116 }}>合格</div>
                            <div className={styles.td} style={{ width: 90 }}>总重量(t)</div>
                            <div className={styles.td} style={{ width: 135, borderRight: 0 }}></div>
                        </div>
                        <div className={styles.row} style={{ height: 80 }}>
                            <div className={styles.td} style={{ width: 71 }}>批号</div>
                            <div className={styles.td} style={{ width: 80 }}>牌号</div>
                            <div className={styles.td} style={{ width: 50 }}>
                                <div>规格</div>
                                <div>(mm)</div>
                            </div>
                            <div className={styles.td} style={{ width: 50 }}>
                                <div>长度</div>
                                <div>(mm)</div>
                            </div>
                            <div className={styles.td} style={{ width: 730, padding: 0, borderRight: 0 }}>
                                <div className={styles.row} style={{ height: 30 }}>
                                    <div className={styles.td} style={{ width: 100 }}>数量</div>
                                    <div className={styles.td} style={{ width: 315 }}>化学成分(熔炼成分)%</div>
                                    <div className={styles.td} style={{ width: 225 }}>力学性能</div>
                                    <div className={styles.td} style={{ width: 90 }}>弯曲性能</div>
                                </div>
                                <div className={styles.row} style={{ height: 50, borderBottom: 0 }}>
                                    <div className={styles.td} style={{ width: 40 }}>捆</div>
                                    <div className={styles.td} style={{ width: 60 }}>理重(t)</div>
                                    <div className={styles.td} style={{ width: 45 }}>C</div>
                                    <div className={styles.td} style={{ width: 45 }}>Mn</div>
                                    <div className={styles.td} style={{ width: 45 }}>Si</div>
                                    <div className={styles.td} style={{ width: 45 }}>P</div>
                                    <div className={styles.td} style={{ width: 45 }}>S</div>
                                    <div className={styles.td} style={{ width: 45 }}>V</div>
                                    <div className={styles.td} style={{ width: 45 }}>Cep</div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        <div>R<sub>el</sub></div>
                                        <div style={{ fontSize: 8 }}>(MPa)</div>
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        <div>Rm</div>
                                        <div style={{ fontSize: 8 }}>(MPa)</div>
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        <div>R°<sub >m</sub>/R°</div>
                                        <div><sub>eL</sub></div>
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        <div>R°<sub>eL</sub>/R</div>
                                        <div><sub>eL</sub></div>
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        <div>A<sub>gt</sub></div>
                                        <div style={{ fontSize: 8 }}>(%)</div>
                                    </div>
                                    <div className={styles.td} style={{ width: 50, lineHeight: '14px' }}>
                                        180°冷弯试验
                                </div>
                                    <div className={styles.td} style={{ width: 40, padding: 0 }}>
                                        <div>反弯</div>
                                        <div>试验</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.td} style={{ width: 45 }}>金相监测</div>
                            <div className={styles.td} style={{ width: 45 }}>重量偏差</div>
                            <div className={styles.td} style={{ width: 45, borderRight: 0 }}>外形尺寸表面质量</div>
                        </div>
                        {warranty.warrantySheetData.map((d, index) => {
                            return (
                                <div key={index} className={index === 6 ? styles.lastRow : styles.row}>
                                    <div className={styles.td} style={{ width: 71 }}>{d.batchNo}</div>
                                    <div className={styles.td} style={{ width: 80 }}>{d.grade}</div>
                                    <div className={styles.td} style={{ width: 50 }}>{`⌀${d.spec}`}</div>
                                    <div className={styles.td} style={{ width: 50 }}>{+d.length * 1000}</div>
                                    <div className={styles.td} style={{ width: 40 }}>{d.number}</div>
                                    <div className={styles.td} style={{ width: 60 }}>{d.weight}</div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.carbon}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.manganese}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.silicon}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.phosphorus}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.sulfur}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.vanadium}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.cep}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.rel}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.rm}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.el}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.l}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.agt}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 50, fontSize: 8,padding: 0 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            const spec = +d.spec;
                                            let rs = r.coldBending;
                                            if (rs === '合格') {
                                                if (spec <= 25) {
                                                    rs = '4d' + rs;
                                                } else if (spec > 25 && spec <= 40) {
                                                    rs = '5d' + rs;
                                                } else if (spec > 40) {
                                                    rs = '6d' + rs;
                                                } else {
                                                    rs = '合格';
                                                }
                                            } 
                                            return (<div key={index}>{rs}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 40 ,padding: 0}}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.backBending}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.metallographic}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.weightDeviation}</div>);
                                        })}
                                    </div>
                                    <div className={styles.td} style={{ width: 45, borderRight: 0 }}>
                                        {d.warrantySheetDataRows.map((r, index) => {
                                            return (<div key={index}>{r.outLooking}</div>);
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {(warranty.warrantySheetData && warranty.warrantySheetData.length < 7) && (
                            <div className={styles.blankBelow}>(以下空白)</div>
                        )}
                        <div className={styles.row} style={{
                            height: 65,
                            borderBottom: 0,
                            borderTop: '1px solid #C4BCBA',
                            paddingTop: 6,
                            paddingLeft: 6,
                            alignItems: 'start',
                            position: 'absolute',
                            bottom: 0,
                        }}>
                            <div style={{ marginRight: 5, width: 80, textAlign: 'right' }}>备注:</div>
                            <div>
                                {warranty.remark.map((re, index) => {
                                    return (<div key={index}>{`${re}。`}</div>);
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.footerRow}>
                            <div className={styles.footerLabel}>公司地址:</div>
                            <div style={{ width: 280 }}>{warranty.companyAddress}</div>
                            <div className={styles.footerLabel}>销售服务热线:</div>
                            <div style={{ width: 160 }}>{warranty.salesPhone}</div>
                            <div className={styles.footerLabel}>质量服务热线:</div>
                            <div style={{ width: 160 }}>{warranty.qualityPhone}</div>
                            <div className={styles.footerLabel}>传真:</div>
                            <div style={{ width: 136 }}>{warranty.fax}</div>
                        </div>
                        <div className={styles.footerRow}>
                            <div className={styles.footerLabel}>公司网址:</div>
                            <div style={{ width: 790 }}>{warranty.website}</div>
                            <div className={styles.footerLabel}>邮编:</div>
                            <div style={{ width: 136 }}>{warranty.postalCode}</div>
                        </div>
                        <div className={styles.footerRow} style={{ paddingLeft: 100 }}>
                            <div className={styles.footerLabel}>发货人:</div>
                            <div style={{ width: 300 }}>{warranty.curator}</div>
                            <div className={styles.footerLabel}>制表人:</div>
                            <div style={{ width: 160 }}>{warranty.editor}</div>
                            <div className={styles.footerLabel}>签发日期:</div>
                            <div>{moment(warranty.date).format('YYYY年MM月DD日')}</div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default DeformedWarranty;