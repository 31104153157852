import React, { Component, Fragment } from 'react';
import styles from './index.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { InputNumber, Input, Button, Drawer, Form, Spin, DatePicker, Icon, message } from 'antd';
import moment from 'moment';
import { getStockDetailByBatchNo, getStockById, getProductLeftNumberNotZeroBatchNo } from '../../../api/stock';

let id = 1;

@observer
class ModifyStock extends Component {

    @observable
    stockDetails = [{
        grade: "",
        length: "",
        prodId: "",
        prodName: "",
        spec: "",
        prodLeftNumber: null,
    }];

    @observable
    originStock = {};

    @observable
    loading = false;

    @observable
    originLoading = false;

    @observable
    modifyStockId = '';

    @observable
    leftNumberNotZeroBatchNo = [];

    componentDidMount() {
        let { modifyStockId } = this.props;
        this.modifyStockId = modifyStockId;
        this.fetch();
    }

    componentWillReceiveProps(nextProps) {
        if (this.modifyStockId !== nextProps.modifyStockId) {
            this.modifyStockId = nextProps.modifyStockId;
            this.fetch();
        }
    }

    fetch = async () => {
        let { modifyStockId } = this;
        if (modifyStockId && modifyStockId !== '') {
            try {
                this.originLoading = true;
                let response = await getStockById(modifyStockId);
                let { data } = response;
                if (data.code === 0) {
                    this.originStock = data.data;
                    id = this.originStock.stockProducts.length - 1;
                    const { form } = this.props;
                    let keys = [];
                    this.stockDetails = [];
                    for (let i = 0; i <= id; i++) {
                        keys.push(i);
                        this.stockDetails.push({
                            grade: this.originStock.stockProducts[i].grade,
                            length: this.originStock.stockProducts[i].product.length,
                            prodId: this.originStock.stockProducts[i].product.prodId,
                            prodName: this.originStock.stockProducts[i].product.prodName,
                            spec: this.originStock.stockProducts[i].product.spec,
                            prodLeftNumber: this.originStock.stockProducts[i].product.prodLeftNumber,
                        });
                    }
                    form.setFieldsValue({ keys: keys });
                }
            }
            finally {
                this.originLoading = false;
            }
        }
    }

    queryProductLeftNumberNotZeroBatchNo = async () => {
        try {
            this.loading = true;
            let resp = await getProductLeftNumberNotZeroBatchNo();
            let { data } = resp;
            if (data.code === 0) {
                this.leftNumberNotZeroBatchNo = data.data;
            }
            else {
                message.warn(data.msg);
            }
        }
        finally {
            this.loading = false;
        }
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.outTime) {
                    values.outTime = moment(values.outTime).format('YYYY-MM-DD HH:mm');
                }
                let { updateStock } = this.props;
                updateStock && updateStock(this.requestDataAssemb(values));
                this.destoryPage();
            }
        });
    };

    destoryPage = () => {
        this.props.form.resetFields();
        id = 1;
        this.stockDetails = [{
            grade: "",
            length: "",
            prodId: "",
            prodName: "",
            spec: "",
            prodLeftNumber: null,
        }];
        this.onClose();
    }

    requestDataAssemb = (values) => {
        let requestData = {};
        let { consignee, licensePlate, phone, outTime, keys, batchNo, number } = values;
        let { stockDetails } = this;
        requestData.warrantySheetNo = this.originStock.warrantySheetNo;
        requestData.stockId = this.originStock.stockId;
        requestData.consignee = consignee;
        requestData.licensePlate = licensePlate;
        requestData.phone = phone;
        requestData.outTime = outTime;
        requestData.stockProducts = keys.map(key => {
            return (
                {
                    batchNo: batchNo[key],
                    number: number[key],
                    prodId: stockDetails[key].prodId,
                }
            );
        })
        return requestData;
    }

    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(++id);
        form.setFieldsValue({
            keys: nextKeys,
        });
        this.stockDetails.push({
            grade: "",
            length: "",
            prodId: "",
            prodName: "",
            spec: "",
            prodLeftNumber: null,
        });
    };

    onClose = () => {
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose();
    }

    queryDetailByBatchNo = async (batchNo, key) => {
        if (batchNo && batchNo !== '') {
            try {
                this.loading = true;
                let resp = await getStockDetailByBatchNo(batchNo);
                let { data } = resp;
                if (data && data.code === 0 && data.data) {
                    let respStockDetail = data.data;
                    if (this.stockDetails[key]) {
                        this.stockDetails[key].prodName = respStockDetail.prod_name;
                        this.stockDetails[key].grade = respStockDetail.grade;
                        this.stockDetails[key].length = respStockDetail.length;
                        this.stockDetails[key].spec = respStockDetail.spec;
                        this.stockDetails[key].prodId = respStockDetail.prod_id;
                        this.stockDetails[key].prodLeftNumber = respStockDetail.prod_left_number;
                    }
                }
                else {
                    this.resetStockDetails(key);
                }
            }
            finally {
                this.loading = false;
            }
        }
        else {
            this.resetStockDetails(key);
        }
    }

    resetStockDetails = (key) => {
        if (this.stockDetails[key]) {
            for (let objKeys in this.stockDetails[key]) {
                this.stockDetails[key][objKeys] = '';
            }
        }
        let { resetFields } = this.props.form;
        resetFields(`number[${key}]`, '');
    }

    render() {
        let { show } = this.props;
        let { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [0] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k) => {
            let currentStockDetailsDisplay = this.stockDetails && (k < this.stockDetails.length) && this.stockDetails[k];
            let initBatchNo = '';
            let initNumber = '';
            let { stockProducts } = this.originStock;
            if (stockProducts && k < stockProducts.length) {
                initBatchNo = stockProducts[k].batchNo;
                initNumber = stockProducts[k].number;
            }
            return (
                <div className="stockAddDetailRow" key={k}>
                    <Form.Item required={true} >
                        {getFieldDecorator(`batchNo[${k}]`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请填写",
                                },
                            ],
                            initialValue: initBatchNo,
                        })(<Input
                            style={{ width: 160, marginRight: 8, fontSize: 12 }}
                            placeholder='炉批号'
                            onChange={e => {
                                this.queryDetailByBatchNo(e.target.value, k);
                                if (e.target.value !== initBatchNo) {
                                    let number = this.props.form.getFieldsValue().number;
                                    number[k] = '';
                                    this.props.form.setFieldsValue({ number });
                                } else {
                                    let number = this.props.form.getFieldsValue().number;
                                    number[k] = initNumber;
                                    this.props.form.setFieldsValue({ number });
                                }
                            }}
                            allowClear={true} 
                            onFocus={this.queryProductLeftNumberNotZeroBatchNo}
                        />)}
                    </Form.Item>
                    <div style={{ display: 'flex', alignItems: 'center', minWidth: 640 }}>
                        <div className={styles.stockDetailsData}>
                            <Spin spinning={this.loading}>
                                {currentStockDetailsDisplay && (this.stockDetails[k].prodName || '--')}
                            </Spin>
                        </div>
                        <div className={styles.stockDetailsData}>
                            <Spin spinning={this.loading}>
                                {currentStockDetailsDisplay && (this.stockDetails[k].grade || '--')}
                            </Spin>
                        </div>
                        <div className={styles.stockDetailsData}>
                            <Spin spinning={this.loading}>
                                {currentStockDetailsDisplay &&
                                    (this.stockDetails[k].spec ?
                                        (this.stockDetails[k].prodName === '钢坯' ? this.stockDetails[k].spec
                                            : `⌀${this.stockDetails[k].spec}`)
                                        : '--')}
                            </Spin>
                        </div>
                        <div className={styles.stockDetailsData}>
                            <Spin spinning={this.loading}>
                                {currentStockDetailsDisplay && (this.stockDetails[k].length || '--')}
                            </Spin>
                        </div>
                        <div className={styles.stockDetailsData}>
                            <Spin spinning={this.loading}>
                                {currentStockDetailsDisplay && (`${this.stockDetails[k].prodLeftNumber}` || '--')}
                            </Spin>
                        </div>
                    </div>
                    <Form.Item required={true} >
                        {getFieldDecorator(`number[${k}]`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [
                                {
                                    required: true,
                                    message: "请填写",
                                },
                            ],
                            initialValue: initNumber,
                        })(
                            <InputNumber
                                placeholder="出库数量"
                                style={{ width: 100, marginRight: 8, fontSize: 12 }}
                                min={0}
                                max={this.stockDetails[k] && +((+this.stockDetails[k].prodLeftNumber) + initNumber)}
                                step={1}
                                disabled={!(currentStockDetailsDisplay && this.stockDetails[k].prodId !== '')}
                            />)}
                    </Form.Item>
                    {(keys.length > 1) && (
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.remove(k)}
                        />
                    )}
                </div>
            );
        })

        return (
            <Fragment>
                <Drawer
                    title="修改出库记录"
                    height={600}
                    onClose={this.destoryPage}
                    visible={show}
                    placement='bottom'
                >
                    <div className={styles.box}>
                        <Spin spinning={this.originLoading}>
                            <Form layout='inline' onSubmit={this.handleSubmit}>
                                <div className={styles.row}>
                                    <Form.Item label='收货单位'>
                                        {getFieldDecorator('consignee',
                                            {
                                                rules: [{ required: true, message: '请填写' }],
                                                initialValue: this.originStock.consignee
                                            })
                                            (<Input
                                                style={{ width: 250, marginRight: 20 }}
                                                placeholder='请填写收货单位名称'
                                                allowClear
                                            />)}
                                    </Form.Item>
                                    <Form.Item label='车牌号'>
                                        {getFieldDecorator('licensePlate',
                                            {
                                                rules: [{ required: true, message: '请填写' }],
                                                initialValue: this.originStock.licensePlate
                                            })
                                            (<Input
                                                style={{ width: 160, marginRight: 20 }}
                                                placeholder='请填写车牌号'
                                                allowClear
                                            />)}
                                    </Form.Item>
                                    <Form.Item label='手机号'>
                                        {getFieldDecorator('phone',
                                            {
                                                rules: [
                                                    { required: true, message: '请填写' },
                                                    {
                                                        validator: async (rule, value, callback) => {
                                                            if (value && value !== '' && !(/^1[3456789]\d{9}$/.test(value))) {
                                                                callback('手机号不合法');
                                                            }
                                                            callback();
                                                        }
                                                    }],
                                                initialValue: this.originStock.phone

                                            })
                                            (<Input
                                                style={{ width: 160, marginRight: 20 }}
                                                placeholder='请填写货主手机号'
                                                allowClear
                                            />)}
                                    </Form.Item>
                                    <Form.Item label='出库日期'>
                                        {getFieldDecorator('outTime',
                                            {
                                                rules: [{ required: true, message: '请填写' }],
                                                initialValue: moment(this.originStock.outTime)
                                            })
                                            (<DatePicker
                                                style={{ width: 160 }}
                                                showTime={{ format: 'YYYY-MM-DD HH:mm' }}
                                                placeholder="请选择日期"
                                                allowClear={false}
                                            />)}
                                    </Form.Item>
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.detailhead}>
                                        <div className={styles.detailTitle} style={{ width: 195 }}>炉批号</div>
                                        <div className={styles.detailTitle} style={{ width: 160 }}>产品名称</div>
                                        <div className={styles.detailTitle} style={{ width: 160 }}>牌号</div>
                                        <div className={styles.detailTitle} style={{ width: 160 }}>规格</div>
                                        <div className={styles.detailTitle} style={{ width: 160 }}>长度(m)</div>
                                        <div className={styles.detailTitle} style={{ width: 160 }}>库存量</div>
                                        <div className={styles.detailTitle} style={{ width: 95 }}>出库数量</div>
                                    </div>
                                    <div className={styles.detailDataBox}>
                                        {formItems}
                                    </div>
                                    <div className={styles.detailBtnRow}>
                                        <Form.Item>
                                            <Button type="dashed" onClick={this.add} style={{ width: 100, marginBottom: 16 }}>
                                                <Icon type="plus" /> 增加
                                    </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={styles.addStockBtn}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: 80 }}
                                            loading={this.props.loading}
                                            disabled={this.props.loading}
                                        >
                                            确定
                                    </Button>
                                    </Form.Item>
                                    <Button
                                        type="default"
                                        style={{ width: 80 }}
                                        onClick={this.destoryPage}
                                    >
                                        取消
                                    </Button>
                                </div>
                            </Form>
                        </Spin>
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}

export default Form.create()(ModifyStock);