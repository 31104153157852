import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { allUsers, register, getUserInfoById, updateUserInfo, resetPassword, deleteUser } from '../../api/user';
import AddUser from './add';
import ModifyUser from './modify';
// @ts-ignore
import styles from './index.module.scss';
import { Table, Popconfirm, Button, Modal, message } from 'antd';
import ResetPassword from './reset';


@observer
class UserManage extends Component {

    @observable pageNum = 1;
    @observable pageSize = 10;
    @observable total = 0;
    @observable userList = [];
    @observable loading = false;
    @observable roleList = [];
    @observable addVisiable = false;
    @observable modifyUser = {};
    @observable modifyVisiable = false;
    @observable resetUserId = {};
    @observable resetVisiable = false;

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        try {
            this.loading = true;
            const resp = await allUsers(this.pageNum, this.pageSize);
            const { data } = resp;
            if (data.code === 0) {
                this.userList = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    insertUser = async (values) => {
        try {
            this.loading = true;
            const resp = await register(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('新建成功');
                this.addVisiable = false;
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    queryUser = async (userId) => {
        try {
            this.loading = true;
            const resp = await getUserInfoById(userId);
            const { data } = resp;
            if (data.code === 0) {
                this.modifyUser = data.data;
                this.modifyVisiable = true;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    modifyUserInfo = async (values) => {
        try {
            this.loading = true;
            const resp = await updateUserInfo(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('修改成功');
                this.onModifyCanceled();
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    reseUserPassword = async (values) => {
        try {
            this.loading = true;
            const resp = await resetPassword(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('重置成功');
                this.onResetCanceled();
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    deleteUser = async (userId) => {
        try {
            this.loading = true;
            const resp = await deleteUser({userId});
            const { data } = resp;
            if (data.code === 0) {
                message.success('删除成功');
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    onAddClicked = () => {
        this.addVisiable = true;
    }

    onAddCanceled = () => {
        this.addVisiable = false;
    }

    onModifyCanceled = () => {
        this.modifyVisiable = false;
        this.modifyUser = {};
    }

    onResetClicked = (userId) => {
        this.resetUserId = userId;
        this.resetVisiable = true;
    }

    onResetCanceled = () => {
        this.resetUserId = '';
        this.resetVisiable = false;
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        this.fetch();
    }

    render() {
        const { userList, pageNum, pageSize, total } = this;
        return (
            <Fragment>
                <div style={{ marginBottom: 20 }}>
                    <Button
                        type='primary'
                        onClick={this.onAddClicked}
                    >
                        新增用户
                    </Button>
                </div>
                <Modal
                    title='新增用户'
                    width={500}
                    visible={this.addVisiable}
                    onCancel={this.onAddCanceled}
                    footer={null}
                >
                    <AddUser
                        // @ts-ignore
                        insertUser={this.insertUser} />
                </Modal>
                <Modal
                    title='修改用户'
                    width={500}
                    visible={this.modifyVisiable}
                    onCancel={this.onModifyCanceled}
                    footer={null}
                >
                    <ModifyUser
                        // @ts-ignore
                        modifyUser={this.modifyUserInfo}
                        user={this.modifyUser}
                    />
                </Modal>
                <Modal
                    title='重置密码'
                    width={500}
                    visible={this.resetVisiable}
                    onCancel={this.onResetCanceled}
                    footer={null}
                >
                    <ResetPassword
                        // @ts-ignore
                        resetPassword={this.reseUserPassword}
                        userId={this.resetUserId}
                    />
                </Modal>
                <Table
                    rowKey={record => record.userId}
                    // @ts-ignore
                    columns={[...this.columns]}
                    dataSource={[...userList]}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            this.fetch();
                        }
                    }}
                    onChange={this.onTableChange}
                />
            </Fragment>
        );
    }

    columns = [
        {
            key: 'userName',
            title: '用户名',
            dataIndex: 'userName',
        },
        {
            key: 'mobile',
            title: '手机号码',
            dataIndex: 'mobile',
        },
        {
            key: 'roleName',
            title: '用户角色',
            dataIndex: 'roleName',
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 240,
            render: (record) => {
                return (
                    <div>
                        <span className={styles.modify} onClick={() => this.queryUser(record.userId)}>修改</span>
                        <span className={styles.modify} onClick={() => this.onResetClicked(record.userId)}>重置密码</span>
                        <span className={styles.del}>
                            <Popconfirm
                                title="确定要删除吗?"
                                okText="是"
                                cancelText="否"
                                onConfirm={() => this.deleteUser(record.userId)}
                            >
                                删除
                            </Popconfirm>
                        </span>
                    </div>
                );
            }
        },
    ]
}

export default UserManage;