import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Pagination, Empty, Popconfirm, Spin } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import moment from 'moment';
import AuthLayer from '../../../component/authLayer';

/**
 * 圆钢
 */

@observer
class Bound extends Component {

    @observable
    dataSource = [];

    @observable
    pageNum = 1;

    @observable
    pageSize = 10;

    @observable
    total = 0;

    observable
    loading = false;

    componentDidMount() {
        let { list, pageNum, pageSize, total, loading } = this.props;
        this.dataSource = list;
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        this.total = total;
        this.loading = loading;
    }

    componentWillReceiveProps(nextProps) {
        if (this.dataSource !== nextProps.list) {
            this.dataSource = nextProps.list;
        }
        if (this.pageNum !== nextProps.pageNum) {
            this.pageNum = nextProps.pageNum;
        }
        if (this.pageSize !== nextProps.pageSize) {
            this.pageSize = nextProps.pageSize;
        }
        if (this.total !== nextProps.total) {
            this.total = nextProps.total;
        }
        if (this.loading !== nextProps.loading) {
            this.loading = nextProps.loading;
        }
    }

    del = (batchNo) => {
        let { del } = this.props;
        del && del(batchNo);
    }

    render() {
        let { pageNum, pageSize, total } = this;
        return (
            <div style={{ minWidth: '1200px' }}>
                <Spin spinning={this.loading}>
                    <div className={styles.table}>
                        <div className={styles.thead}>
                            {this.columns.map(item => {
                                return (
                                    <div key={item.key} style={{ width: `${item.width}px`, textAlign: `${item.align}` }}>
                                        {item.render ? (item.render) : (item.title)}
                                    </div>
                                );
                            })}
                        </div>
                        {this.dataSource.length > 0 ? (
                            <div>
                                {this.dataSource.length > 0 && (
                                    this.dataSource.map(item => {
                                        return (
                                            <div key={item.batchNo} className={styles.tr}>
                                                <div className={styles.batchNo}>{item.batchNo}</div>
                                                <div className={styles.td}>
                                                    {(item.children && item.children.length > 0) && (
                                                        <div>
                                                            {item.children.map((detail, index) => {
                                                                return (
                                                                    <div key={index} className={styles.row}>
                                                                        <div className={styles.furnaceNo}>{detail.furnaceNo}</div>
                                                                        <div className={styles.cell}>{detail.rel}</div>
                                                                        <div className={styles.cell}>{detail.rm}</div>
                                                                        <div className={styles.cell}>{detail.a}</div>
                                                                        <div className={styles.cell}>{detail.coldBending}</div>
                                                                        <div className={styles.cell} style={{ width: '250px' }}>
                                                                            <div className={styles.akv}>{detail.akv1}</div>
                                                                            <div>-</div>
                                                                            <div className={styles.akv}>{detail.akv2}</div>
                                                                            <div>-</div>
                                                                            <div className={styles.akv}>{detail.akv3}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={styles.batchTime}>{moment(item.testingTime).format("YYYY-MM-DD hh:mm")}</div>
                                                <div className={styles.btn}>
                                                    {item.deliver === 0 ? (
                                                        <AuthLayer>
                                                            <div className={styles.modify} onClick={() => {
                                                                let { modify } = this.props;
                                                                modify && modify(item.batchNo);
                                                            }}>
                                                                修改
                                                            </div>
                                                            <div className={styles.del}>
                                                                <Popconfirm
                                                                    title="确定要删除吗?"
                                                                    onConfirm={() => this.del(item.batchNo)}
                                                                    okText="是"
                                                                    cancelText="否"
                                                                >
                                                                    删除
                                                                </Popconfirm>
                                                            </div>
                                                        </AuthLayer>
                                                    ) : (
                                                            <div style={{ color: '#CCCCCC' }}>已入库</div>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        ) : (<Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />)}
                    </div>
                    {this.total > 0 && (
                        <div className={styles.pagination}>
                            <Pagination
                                current={pageNum}
                                pageSize={pageSize}
                                total={total}
                                showSizeChanger={true}
                                showTotal={(total) => { return `共${total}条` }}
                                onShowSizeChange={(current, size) => {
                                    this.pageNum = current;
                                    this.pageSize = size;
                                    let { onShowSizeChange } = this.props;
                                    onShowSizeChange && onShowSizeChange(current, size);
                                }}
                                onChange={(pagination) => {
                                    this.pageNum = pagination;
                                    let { onTableChange } = this.props;
                                    onTableChange && onTableChange(this.pageNum);
                                }}
                            />
                        </div>
                    )}
                </Spin>
            </div >
        );
    }

    columns = [
        {
            key: 'batchNo',
            title: '批号',
            dataIndex: 'batchNo',
            width: 120
        },
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
            width: 120
        },
        {
            key: 'rel',
            title: 'ReL(MPa)',
            dataIndex: 'rel',
            align: 'center',
            width: 90,
            render: (
                <div>
                    <div>R<sub>eL</sub></div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                </div>
            )
        },
        {
            key: 'rm',
            title: 'Rm(MPa)',
            dataIndex: 'rm',
            align: 'center',
            width: 90,
            render: (
                <div>
                    <div>R<sub>m</sub></div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(MPa)</div>
                </div>
            )
        },
        {
            key: 'a',
            title: 'A',
            dataIndex: 'a',
            align: 'center',
            width: 90,
            render: (
                <div>
                    <div>A</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>(%)</div>
                </div>
            )
        },
        {
            key: 'coldBending',
            title: '180°',
            dataIndex: 'coldBending',
            align: 'center',
            width: 90,
            render: (
                <div>
                    <div>180°</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>d=a</div>
                </div>
            )
        },
        {
            key: 'akv',
            title: 'Akv(纵向)',
            dataIndex: 'akv',
            align: 'center',
            width: 250,
            render: (
                <div>
                    <div>Akv(纵向)</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal' }}>20°C(J)</div>
                </div>
            )
        },
        {
            key: 'testingTime',
            title: '创建时间',
            dataIndex: 'testingTime',
            align: 'center',
            width: 165
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 129
        },
    ]
}

export default Bound;