import React, { Component } from 'react';
import { InputNumber, Input, Form, DatePicker, Button, Select, Modal, message } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { isFurnaceNoExist } from '../../../api/billet';
import styles from './index.module.scss';
import { getGradeNameList } from '../../../api/grade';

const { Option } = Select;

@observer
class ModifyComponentAnalysis extends Component {

    @observable
    show = false;

    @observable gradeList = [];
    @observable loading = false;

    componentDidMount() {
        this.fetchGradeList();
    }

    fetchGradeList = async () => {
        try {
            this.loading = true;
            const resp = await getGradeNameList();
            const { data } = resp;
            if (data.code === 0) {
                this.gradeList = data.data;
            } else {
                message.error('查询牌号出错');
            }
        } finally {
            this.loading = false;
        }
    }

    onClose = () => {
        let { drawerOnClose } = this.props;
        drawerOnClose && drawerOnClose(false);
    }

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.analysisTime) {
                    values.analysisTime = moment(values.analysisTime).format('YYYY-MM-DD HH:mm:ss');
                }
                let { updateById } = this.props;
                updateById && updateById(values);
                this.props.form.resetFields();
            }
        });
    };

    calcCeq = (grade, carbon, manganese) => {
        !grade && (grade = this.props.form.getFieldValue('grade'));
        !carbon && (carbon = this.props.form.getFieldValue('carbon'));
        !manganese && (manganese = this.props.form.getFieldValue('manganese'));
        if (carbon && manganese && grade === 'HRB400E') {
            this.props.form.setFieldsValue({ cep: ((+carbon) + ((+manganese) / 6)) })
        }
        else {
            this.props.form.setFieldsValue({ cep: null })
        }
    }

    render() {
        let { show, billet } = this.props;
        if (!billet) {
            billet = {};
        }
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Modal
                    title="修改化验记录"
                    width={750}
                    onCancel={this.onClose}
                    visible={show}
                    bodyStyle={{ paddingBottom: 50 }}
                    footer={null}
                >
                    <Form layout='vertical' onSubmit={this.handleSubmit}>
                        <div className={styles.row}>
                            <Form.Item label='炉号'>
                                {getFieldDecorator('furnaceNo',
                                    {
                                        rules: [
                                            { required: true, message: '请输入炉号' },
                                            {
                                                validator: async (rule, value, callback) => {
                                                    let resp = await isFurnaceNoExist(value);
                                                    let { data } = resp;
                                                    if (value !== billet.furnaceNo && data.code === 0 && data.data) {
                                                        callback('炉号已经存在');
                                                    }
                                                    callback();
                                                }
                                            }
                                        ],
                                        initialValue: billet.furnaceNo
                                    })
                                    (<Input maxLength={20} style={{ width: 190 }} />)}
                            </Form.Item>
                            <Form.Item label='牌号'>
                                {getFieldDecorator('grade',
                                    {
                                        rules: [{ required: true, message: '请输入牌号' }],
                                        initialValue: billet.grade
                                    })
                                    (<Select
                                        style={{ width: 190 }}
                                        placeholder='请选择'
                                        onChange={(v) => this.calcCeq(v.toString(), null, null)}
                                    >
                                         {(this.gradeList && this.gradeList.length > 0) && (
                                            this.gradeList.map((g, i) => {
                                                return (
                                                    <Option key={i} value={g}>{g}</Option>
                                                );
                                            })
                                        )}
                                    </Select>)}
                            </Form.Item>
                            <Form.Item label='时间'>
                                {getFieldDecorator('analysisTime',
                                    {
                                        rules: [{ required: true, message: '请输入时间' }],
                                        initialValue: moment(billet.analysisTime)
                                    })
                                    (<DatePicker
                                        showTime={{ format: 'YYYY-MM-DD HH:mm' }}
                                        placeholder="请选择时间"
                                        locale={locale}
                                        style={{ width: 190 }}
                                    />)}
                            </Form.Item>
                        </div>
                        <div className={styles.row}>
                            <Form.Item label='碳(C)'>
                                {getFieldDecorator('carbon',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.carbon
                                    })
                                    (<InputNumber
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        style={{ width: 190 }}
                                        onChange={(v) => this.calcCeq(null, v, null)}
                                    />)}
                            </Form.Item>
                            <Form.Item label='硫(S)'>
                                {getFieldDecorator('sulfur',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.sulfur
                                    })
                                    (<InputNumber min={0} max={100} step={0.001} style={{ width: 190 }} />)}
                            </Form.Item>
                            <Form.Item label='锰(Mn)'>
                                {getFieldDecorator('manganese',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.manganese
                                    })
                                    (<InputNumber
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        style={{ width: 190 }}
                                        onChange={(v) => this.calcCeq(null, null, v)}
                                    />)}
                            </Form.Item>
                        </div>
                        <div className={styles.row}>
                            <Form.Item label='磷(P)'>
                                {getFieldDecorator('phosphorus',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.phosphorus
                                    })
                                    (<InputNumber min={0} max={100} step={0.001} style={{ width: 190 }} />)}
                            </Form.Item>
                            <Form.Item label='硅(Si)'>
                                {getFieldDecorator('silicon',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.silicon
                                    })
                                    (<InputNumber min={0} max={100} step={0.01} style={{ width: 190 }} />)}
                            </Form.Item>
                            <Form.Item label='钒(V)'>
                                {getFieldDecorator('vanadium',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.vanadium
                                    })
                                    (<InputNumber min={0} max={100} step={0.001} style={{ width: 190 }} />)}
                            </Form.Item>
                        </div>
                        <div className={styles.row}>
                            <Form.Item label='ceq'>
                                {getFieldDecorator('cep',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.cep
                                    })
                                    (<InputNumber min={0} max={100} step={0.01} precision={2} style={{ width: 190 }} />)}
                            </Form.Item>
                            <Form.Item label='交付支数'>
                                {getFieldDecorator('deliverNumber',
                                    {
                                        rules: [{ required: false }],
                                        initialValue: billet.deliverNumber
                                    })
                                    (<InputNumber
                                        min={0}
                                        max={100}
                                        step={1}
                                        style={{ width: 190 }}
                                        disabled={billet.deliver !== 0}
                                    />)}
                            </Form.Item>
                        </div>
                        <div className={styles.row}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={this.hasErrors(getFieldsError())}
                                    style={{ minWidth: 100 }}
                                >
                                    确定
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ModifyComponentAnalysis);