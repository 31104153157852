import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
// @ts-ignore
import styles from './index.module.scss';
import { observer } from 'mobx-react';

@observer
class ModifyClients extends Component {

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { insertClients } = this.props;
                insertClients && insertClients(values);
                this.props.form.resetFields();
            }
        });
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div className={styles.row}>
                        <Form.Item label='收货单位'>
                            {getFieldDecorator('customerName',
                                {
                                    rules: [{ required: true, message: '请输入收货单位名称' },],
                                })
                                (<Input maxLength={10} style={{ width: 300 }} placeholder='请输入收货单位名称' />)}
                        </Form.Item>
                    </div>
                    <div className={styles.row}>
                        <Form.Item label='手机号'>
                            {getFieldDecorator('phone',
                                {
                                    rules: [
                                        { required: true, message: '请输入手机号码' },
                                        { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号码' }
                                    ],
                                })
                                (<Input maxLength={11} style={{ width: 300 }} placeholder='请输入手机号码' />)}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={this.hasErrors(getFieldsError()) || this.props.addSubmitting}
                                loading={this.props.addSubmitting}
                                style={{ minWidth: 100 }}
                            >
                                确定
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create()(ModifyClients);