import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ProductModifyRecordSearch from './search';
// @ts-ignore
import styles from './index.module.scss';
import { Button, Table } from 'antd';
import { getProductModifyRecordList } from '../../api/product';
import { getEnv } from '../../utils/http';
import axios from 'axios';

const columns = [
    {
        key: 'prodName',
        title: '品名',
        dataIndex: 'prodName',
    },
    {
        key: 'batchNo',
        title: '炉批号',
        dataIndex: 'batchNo',
    },
    {
        key: 'originNum',
        title: '原始数量',
        dataIndex: 'originNum',
    },
    {
        key: 'newNum',
        title: '修改数量',
        dataIndex: 'newNum',
    },
    {
        key: 'updateTime',
        title: '修改时间',
        dataIndex: 'updateTime',
    },
    {
        key: 'updateUser',
        title: '修改用户',
        dataIndex: 'updateUser',
    },
]


@observer
class ProductModifyRecord extends Component {

    @observable pageNum = 1;
    @observable pageSize = 10;
    @observable total = 0;

    param = {};

    @observable loading = false;
    @observable list = [];

    componentDidMount() {
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param })
    }

    queryList = async (param) => {
        try {
            this.loading = true;
            let resp = await getProductModifyRecordList(param);
            let { data } = resp;
            if (data.code === 0) {
                this.list = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            }
        }
        finally {
            this.loading = false;
        }
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    search = (param) => {
        this.param = param;
        let { pageNum, pageSize } = this;
        this.queryList({ pageNum, pageSize, ...this.param });
    }

    export = async () => {
        this.loading = true;
        axios({
            method: "POST",
            url: getEnv() === 'DEV' ? `/product/exportProductModifyRecord` : '/api/product/exportProductModifyRecord',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { ...this.param },
            responseType: 'blob'
        }).then((res) => {
            let blob = res.data;
            let url = window.URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = url;
            el.download = "成品数量修改记录.xlsx";
            el.hidden = true;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.loading = false;
        });
    }

    render() {
        let { pageNum, pageSize, total } = this;
        return (
            <Fragment>
                <ProductModifyRecordSearch
                    // @ts-ignore
                    submit={this.search} />
                <div className={styles.tools}>
                    <Button type='link' onClick={this.export}>导出数据</Button>
                </div>
                <Table
                    rowKey={record => record.recordId}
                    // @ts-ignore
                    columns={columns}
                    dataSource={this.list}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            let { pageNum, pageSize } = this;
                            this.queryList({ pageNum, pageSize, ...this.param });
                        }
                    }}
                    onChange={this.onTableChange}
                />
            </Fragment>
        );
    }
}

export default ProductModifyRecord;