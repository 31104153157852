import React, { Component, Fragment } from 'react';
import { Table, Button, Modal, message, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { getRoleList, insertRole, getRoleById, updateRole, deleteRole } from '../../api/role';
import AddRole from './add';
import ModifyRole from './modify';
// @ts-ignore
import styles from './index.module.scss';
import RoleAuth from './auth';

@observer
class Role extends Component {

    @observable pageNum = 1;
    @observable pageSize = 10;
    @observable total = 0;
    @observable roleList = [];
    @observable loading = false;
    @observable addVisiable = false;
    @observable modifyRole = {};
    @observable modifyVisiable = false;
    @observable authRoleId = '';
    @observable authVisiable = false;

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        try {
            this.loading = true;
            const resp = await getRoleList({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            });
            const { data } = resp;
            if (data.code === 0) {
                this.roleList = data.data.list;
                this.pageNum = data.data.pageNum;
                this.pageSize = data.data.pageSize;
                this.total = data.data.total;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    insert = async (values) => {
        try {
            this.loading = true;
            const resp = await insertRole(values);
            const { data } = resp;
            if (data.code === 0) {
                message.success('新增成功');
                this.fetch();
                this.onAddCanceled();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    queryRole = async (roleId) => {
        try {
            this.loading = true;
            const resp = await getRoleById({ roleId });
            const { data } = resp;
            if (data.code === 0) {
                this.modifyRole = data.data;
                this.modifyVisiable = true;
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    updateRoleBasicInfo = async (values) => {
        try {
            this.loading = true;
            const resp = await updateRole({
                roleId: this.modifyRole.roleId,
                ...values
            });
            const { data } = resp;
            if (data.code === 0) {
                message.success('修改成功');
                this.onModifyCanceled();
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    delete = async (roleId) => {
        try {
            this.loading = true;
            const resp = await deleteRole({ roleId });
            const { data } = resp;
            if (data.code === 0) {
                message.success('删除成功');
                this.fetch();
            } else {
                message.error(data.msg);
            }
        } finally {
            this.loading = false;
        }
    }

    onTableChange = (pagination) => {
        this.pageNum = pagination.current;
        this.fetch();
    }

    onAddClicked = () => {
        this.addVisiable = true;
    }

    onAddCanceled = () => {
        this.addVisiable = false;
    }

    onModifyClicked = (roleId) => {
        this.queryRole(roleId);
    }

    onModifyCanceled = () => {
        this.modifyRole = {};
        this.modifyVisiable = false;
    }

    onAuthClicked = (roleId) => {
        this.authRoleId = roleId;
        this.authVisiable = true;
    }

    onAuthCanceled = () => {
        this.authVisiable = false;
        this.authRoleId = '';
    }

    render() {
        let { pageNum, pageSize, total, roleList } = this;
        return (
            <Fragment>
                <div style={{ marginBottom: 20 }}>
                    <Button
                        type='primary'
                        onClick={this.onAddClicked}
                    >
                        新增角色
                    </Button>
                </div>
                <Table
                    rowKey={record => record.roleId}
                    // @ts-ignore
                    columns={[...this.columns]}
                    dataSource={[...roleList]}
                    loading={this.loading}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        showSizeChanger: true,
                        showTotal: (total) => { return `共${total}条` },
                        onShowSizeChange: (current, size) => {
                            this.pageNum = current;
                            this.pageSize = size;
                            this.fetch();
                        }
                    }}
                    onChange={this.onTableChange}
                />
                <Modal
                    title='新增角色'
                    width={500}
                    visible={this.addVisiable}
                    onCancel={this.onAddCanceled}
                    footer={null}
                >
                    <AddRole
                        // @ts-ignore
                        insertRole={this.insert} />
                </Modal>
                <Modal
                    title='修改角色'
                    width={500}
                    visible={this.modifyVisiable}
                    onCancel={this.onModifyCanceled}
                    footer={null}
                >
                    <ModifyRole
                        // @ts-ignore
                        role={this.modifyRole}
                        modifyRole={this.updateRoleBasicInfo}
                    />
                </Modal>
                <Modal
                    title='设置权限'
                    width={500}
                    visible={this.authVisiable}
                    onCancel={this.onAuthCanceled}
                    footer={null}
                >
                    <RoleAuth
                        roleId={this.authRoleId}
                        onAuthCanceled={this.onAuthCanceled}
                    />
                </Modal>
            </Fragment>
        );
    }

    columns = [
        {
            key: 'roleName',
            title: '角色名称',
            dataIndex: 'roleName',
        },
        {
            key: 'desc',
            title: '角色描述',
            dataIndex: 'desc',
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: 200,
            render: (record) => {
                return (
                    <div>
                        <span className={styles.modify} onClick={() => this.onModifyClicked(record.roleId)}>修改</span>
                        <span className={styles.modify} onClick={() => this.onAuthClicked(record.roleId)}>设置</span>
                        <span className={styles.del}>
                            <Popconfirm
                                title="确定要删除吗?"
                                okText="是"
                                cancelText="否"
                                onConfirm={() => this.delete(record.roleId)}
                            >
                                删除
                            </Popconfirm>
                        </span>
                    </div>
                );
            }
        },
    ]
}

export default Role;