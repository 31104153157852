import React, { Component } from 'react';
import styles from './index.module.scss';
import { Input, Button, InputNumber, Table, message } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { insertBatch, batchNoExist, getLatestBatchNo } from '../../../api/batch';
import { observer } from 'mobx-react';
import { observable } from 'mobx';


@observer
class BilletGroup extends Component {

    @observable
    loading = false;

    @observable
    billetsBatch = [];

    @observable
    batchNo = '';

    @observable
    batchList = [];

    @observable
    batchNoExist = false;

    @observable
    latestBatchNo = '';

    componentDidMount() {
        this.billetsBatch = [];
        this.props.billets.forEach((item, index) => {
            this.billetsBatch[index] = item;
            let { billetId, furnaceNo, leftNumber } = item;
            this.batchList[index] = { billetId, furnaceNo, batchNumber: leftNumber };
        })
        this.show = this.props.show;
        this.queryLatestBatchNo();
    }

    componentWillReceiveProps(nextProps) {
        if (this.billetsBatch !== nextProps.billets) {
            let { billets } = nextProps;
            this.billetsBatch = [];
            billets.forEach((item, index) => {
                this.billetsBatch[index] = item;
                let { billetId, furnaceNo, leftNumber } = item;
                this.batchList[index] = { billetId, furnaceNo, batchNumber: leftNumber };
            })
            this.queryLatestBatchNo();
        }
    }

    remove = (array, item) => {
        for (var i = 0; i < array.length; i++) {
            if (array[i] === item) {
                array.splice(i, 1);
                this.batchList.splice(i, 1);
                break;
            }
        }
    }

    setBatchNumber = (billet, number) => {
        for (var i = 0; i < this.batchList.length; i++) {
            if (this.batchList[i].billetId === billet.billetId) {
                this.batchList[i].batchNumber = number;
                break;
            }
        }
    }

    setBatchNo = (e) => {
        this.batchNo = e.target.value;
        this.queryBatchNoExist(this.batchNo);
    }

    queryBatchNoExist = async (batchNo) => {
        let resp = await batchNoExist(batchNo);
        let { data } = resp;
        this.batchNoExist = (data.code === 0 && data.data);
    }

    queryLatestBatchNo = async () => {
        let resp = await getLatestBatchNo();
        let { data } = resp;
        if (data.code === 0) {
            this.latestBatchNo = data.data || '';
        }
    }

    submit = async () => {
        let { batchNo, batchList } = this;
        let param = { batchNo, batchList };
        for (let b in param.batchList.target) {
            if (!b.batchNumber || b.batchNumber === 0) {
                message.error('支数不能为0');
                return;
            }
        }
        try {
            this.loading = true;
            let resp = await insertBatch(param);
            let { data } = resp;
            if (data.code === 0) {
                this.batchNo = '';
                message.success('组批成功');
            }
            else {
                message.error('操作失败:' + data.data);
            }
        }
        finally {
            let { groupOk } = this.props;
            groupOk && groupOk();
            this.loading = false;
        }
    }

    getLeftNumber = (record) => {
        let { batchList } = this;
        for (let batch in batchList) {
            if (batch.billetId === record.billetId) {
                return batch.batchNumber;
            }
        }
    }

    render() {
        let { billetsBatch } = this;
        return (
            <div>
                {(billetsBatch && billetsBatch.length > 0) && (
                    <div>
                        <div className={styles.row}>
                            <div className={styles.required}>*</div>
                            <div className={styles.label}>轧钢批号:</div>
                            <div className={styles.batchNo}>
                                <Input
                                    maxLength={20}
                                    allowClear
                                    onChange={this.setBatchNo}
                                    autoFocus={true}
                                    value={this.batchNo}
                                    placeholder='请输入轧钢批号'
                                />
                            </div>
                            <div className={styles.latestBatchNo}>
                                {`上个轧钢批号: ${this.latestBatchNo}`}
                            </div>
                            {this.batchNoExist && (
                                <div className={styles.tips}>
                                    请注意：轧钢批号已经存在！继续操作将与原有批次数据合并
                                </div>
                            )}
                        </div>
                        <div className={styles.row}>
                            <Table
                                locale={locale}
                                rowKey={record => record.billetId}
                                columns={this.columns}
                                size="small"
                                dataSource={billetsBatch}
                                pagination={false}
                            />
                        </div>
                        <div className={styles.btn}>
                            <Button
                                type='primary'
                                style={{ marginRight: '20px' }}
                                onClick={this.submit}
                                loading={this.loading}
                                disabled={this.batchNo === '' || this.loading}
                            >
                                确定
                            </Button>
                            <Button
                                type='default'
                                onClick={() => {
                                    let { groupCancel } = this.props;
                                    groupCancel && groupCancel();
                                }}
                            >
                                取消
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    columns = [
        {
            key: 'furnaceNo',
            title: '炉号',
            dataIndex: 'furnaceNo',
        },
        {
            key: 'leftNumber',
            title: '待组批(支)',
            dataIndex: 'leftNumber',
            align: 'center'
        },
        {
            key: 'batchNumber',
            title: '本批支数',
            align: 'center',
            render: (record) => {
                return (
                    <div style={{ width: '120px' }}>
                        <InputNumber
                            min={1}
                            step={1}
                            max={record.leftNumber}
                            precision={0}
                            defaultValue={record.leftNumber}
                            onChange={(value) => this.setBatchNumber(record, value)}
                        />
                    </div>
                );
            }
        },
        {
            key: 'carbon',
            title: '碳(C)',
            dataIndex: 'carbon',
            align: 'center'
        },
        {
            key: 'manganese',
            title: '锰(Mn)',
            dataIndex: 'manganese',
            align: 'center'
        },
        {
            key: 'silicon',
            title: '硅(Si)',
            dataIndex: 'silicon',
            align: 'center'
        },
        {
            key: 'phosphorus',
            title: '磷(P)',
            dataIndex: 'phosphorus',
            align: 'center'
        },
        {
            key: 'sulfur',
            title: '硫(S)',
            dataIndex: 'sulfur',
            align: 'center'
        },
        {
            key: 'vanadium',
            title: '钒(V)',
            dataIndex: 'vanadium',
            align: 'center'
        },
        {
            key: 'cep',
            title: 'ceq',
            dataIndex: 'cep',
            align: 'center'
        },
        {
            key: 'operation',
            title: '操作',
            align: 'center',
            width: '80px',
            render: (record) => {
                return (
                    <div className={styles.remove} onClick={() => {
                        this.remove(this.billetsBatch, record)
                    }}>
                        移除
                    </div>
                );
            }
        },
    ]
}

export default BilletGroup;