import React, { Component } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { columns } from './columns';
import { Popconfirm, Spin, Empty } from 'antd';
import AuthLayer from '../../../component/authLayer';


class StockTable extends Component {

    del = (stockId) => {
        let { delStock } = this.props;
        delStock && delStock(stockId);
    }

    modify = (stockId) => {
        let { onModify } = this.props;
        onModify && onModify(stockId);
    }

    warranty = (stockId) => {
        let { selectWarranty } = this.props;
        selectWarranty && selectWarranty(stockId);
    }

    reGenerate = (stockId) => {
        let { reGenerateWarranty } = this.props;
        reGenerateWarranty && reGenerateWarranty(stockId);
    }

    render() {
        let { dataSource } = this.props;
        return (
            <div className={styles.table}>
                <Spin spinning={this.props.dataLoading}>
                    {(dataSource && dataSource.length === 0) && (
                        <div className={styles.empty}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                    {dataSource.map((stock, index) => {
                        return (
                            <div key={index} className={styles.tableItem}>
                                <div className={styles.consigneeBox}>
                                    <div className={styles.warrantySheetNo}>{stock.warrantySheetNo}</div>
                                    <div className={styles.primaryLabel}>收货单位:</div>
                                    <div className={styles.consignee}>{stock.consignee}</div>
                                    <div className={styles.primaryLabel}>车牌号:</div>
                                    <div className={styles.licensePlate}>{stock.licensePlate}</div>
                                    <div className={styles.primaryLabel}>货主手机:</div>
                                    <div className={styles.phone}>{stock.phone}</div>
                                    <div className={styles.primaryLabel}>出库时间:</div>
                                    <div className={styles.outTime}>{moment(stock.outTime).format("YYYY-MM-DD HH:mm")}</div>
                                </div>
                                <div className={styles.itemBox}>
                                    <div className={styles.thead}>
                                        {columns.map((column, index) => {
                                            return (
                                                <div key={index} style={{ width: column.width, textAlign: column.align }}>
                                                    {column.title}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.tbody}>
                                        {stock.stockProducts && (stock.stockProducts.map((stockProduct, index) => {
                                            return (
                                                <div key={index} className={styles.trow}>
                                                    <div className={styles.rowField}>
                                                        {stockProduct.batchNo}
                                                    </div>
                                                    <div className={styles.rowField}>
                                                        {stockProduct.product.prodName}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: 226 }}>
                                                        {stockProduct.grade}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: 194 }}>
                                                        {stockProduct.product.prodName !== '钢坯' ? `⌀${stockProduct.product.spec}`
                                                            : stockProduct.product.spec}
                                                    </div>
                                                    <div className={styles.rowField} style={{ width: 208 }}>
                                                        {stockProduct.product.length}
                                                    </div>
                                                    <div style={{ minWidth: 55, textAlign: 'right' }}>
                                                        {stockProduct.product.prodName === '钢坯' ? `${stockProduct.number}支`
                                                            : `${stockProduct.number}捆`}
                                                    </div>
                                                </div>
                                            );
                                        }))}
                                    </div>
                                    <div className={styles.curatorBox}>
                                        <div className={styles.curatorLabel}>出库人:</div>
                                        <div className={styles.curatorValue}>{stock.curator}</div>
                                        <div className={styles.opera}>
                                            <div
                                                className={styles.warrantySheetBtn}
                                                onClick={() => this.reGenerate(stock.stockId)}
                                            >
                                                手工生成质保
                                            </div>
                                            <div
                                                className={styles.warrantySheetBtn}
                                                onClick={() => this.warranty(stock.stockId)}
                                            >
                                                查看质保单
                                            </div>
                                            <AuthLayer>
                                                <div
                                                    className={styles.modifyBtn}
                                                    onClick={() => this.modify(stock.stockId)}
                                                >
                                                    修改
                                                </div>
                                                <div className={styles.deleteBtn}>
                                                    <Popconfirm
                                                        title="确定要删除吗?"
                                                        onConfirm={() => this.del(stock.stockId)}
                                                        okText="是"
                                                        cancelText="否"
                                                    >
                                                        删除
                                                    </Popconfirm>
                                                </div>
                                            </AuthLayer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Spin>
            </div>
        );
    }
}

export default StockTable;